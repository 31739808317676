<template>
  <div>
    <div class="card pagination-margin" qid="basic-info-section">
      <div class="card-body" v-if="((activity.additional_client && getClient.slug != activity.additional_client.slug) || activity.additional_client == null)">

        <div class="card" v-if="open_tasks">
          <h5 class="text-dark mb-0">
            {{ $t('projects.tab.tasks') }}

            <button class="btn btn-success pl-3 pr-3 float-right"
                    @click="showAddTaskModal"
                    v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.type && activity.type != 'risk_assessment' && activity.status != 'closed' && activity.status != 'completed'"
                    qid="question-create-button"
                    :disabled="loadingTasks"
            >
              <font-awesome-icon icon="plus"/>
              {{ $t('tasks.assessment.add_from_template') }}
            </button>

            <b-button class="float-right"
                      variant="light"
                      v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                      @click="showReminderModal(object)"
                      qid="question-send_reminder-button"
            >
              <font-awesome-icon icon="reply-clock"/>
              {{ $t('tasks.send_reminders') }}
            </b-button>

            <b-button class="float-right"
                      variant="light"
                      v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                      @click="showDefaultAssigneesModal(object)"
                      qid="question-send_reminder-button"
            >
              <font-awesome-icon icon="user-tag"/>
              {{ $t('projects.create.task_assignees') }}
            </b-button>

            <button class="btn btn-secondary-dark float-right"
                    @click="downloadTasks"
                    qid="reporting-download_media-button"
                    v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.type && (activity.type == 'update' || activity.type == 'validation' || activity.type == 'security_measure' || activity.type == 'control')"
            >
              <font-awesome-icon icon="file-download"/>
            </button>


          </h5>

          <div class="row tree-filter-row">
            <div class="col-sm-2 tree-filter-col">
              <div class="card filter-tree-view-container">
                <div class="card-body p-0 tree-filter-col">
                  <AssessmentTaskSideFilter
                      v-if="SideFilterRenderComponent"
                      :selectedCategory="selectedCategory"
                      :updateList="updateChildList"
                      :assessment="activity"
                      @updateSelected="updateSelectedFilter"
                      :loadingTasks="loadingTasks"
                  ></AssessmentTaskSideFilter>
                </div>
              </div>
            </div>

            <div class="col-sm-10 tree-result-col">
              <AssessmentTaskTopBar
                :selectedCategory="selectedCategory"
                @forceSideFilterRenderComponent="forceSideFilterRenderComponent"
              ></AssessmentTaskTopBar>

              <div class="tree-result-main">

                <table class="table table-responsive-md table-list-secondary">
                  <thead>
                  <tr>
                    <th>
                      {{ $t('projects.table.name') }}
                    </th>

                    <th>
                      {{ $t('tasks.table.task_status') }}
                    </th>
                    <th>
                      {{ $t('tasks.modal.response_label') }}
                    </th>
                    <th>
                      {{ $t('system.quality_control') }}
                    </th>
                    <th width="210"></th>
                  </tr>
                  </thead>
                  <tbody v-if="open_tasks && open_tasks.length && selectedCategory && selectedCategory.id">
                    <tr v-for="(object, index) in open_tasks"
                        :id="'table-row-' + index"
                        :key="'table-row-' + index">
                      <b-popover
                          :target="'table-row-' + index"
                          boundary="viewport"
                          v-if="object.code == 'system_impact_assessment' || object.code == 'risk_assessment' || object.code == 'control_assessment'"
                          triggers="hover"
                          placement="topleft"
                          :delay="{ show: 500, hide: 0 }"
                      >
                        <div v-if="object.code == 'system_impact_assessment'">
                          <b-form-group>
                            <label qid="create-p-users-label">
                              {{ $t('processes.confidentiality.title') }}
                            </label>
                            <p v-if="object.confidentiality">
                              {{ object.confidentiality }}
                            </p>
                            <p v-if="!object.confidentiality">
                              {{ $t('system.no_results') }}
                            </p>
                          </b-form-group>
                          <b-form-group>
                            <label qid="create-p-users-label">
                              {{ $t('processes.integrity.title') }}
                            </label>
                            <p v-if="object.integrity">
                              {{ object.integrity }}
                            </p>
                            <p v-if="!object.integrity">
                              {{ $t('system.no_results') }}
                            </p>
                          </b-form-group>
                          <b-form-group>
                            <label qid="create-p-users-label">
                              {{ $t('processes.availability.title') }}
                            </label>
                            <p v-if="object.availability">
                              {{ object.availability }}
                            </p>
                            <p v-if="!object.availability">
                              {{ $t('system.no_results') }}
                            </p>
                          </b-form-group>
                          <b-form-group>
                            <label qid="create-p-users-label">
                              {{ $t('tasks.modal.rto_label') }}
                            </label>
                            <p v-if="object.rto_number">
                              {{ object.rto_number }} {{ object.rto_type }}
                            </p>
                            <p v-if="!object.rto_number">
                              {{ $t('system.no_results') }}
                            </p>
                          </b-form-group>
                          <b-form-group>
                            <label qid="create-p-users-label">
                              {{ $t('tasks.modal.rpo_label') }}
                            </label>
                            <p v-if="object.rpo_number">
                              {{ object.rpo_number }} {{ object.rpo_type }}
                            </p>
                            <p v-if="!object.rpo_number">
                              {{ $t('system.no_results') }}
                            </p>
                          </b-form-group>
                          <b-form-group>
                            <label qid="create-p-users-label">
                              {{ $t('tasks.modal.remarks_label') }}
                            </label>
                            <p class="text-formatted" v-if="object.remarks">{{ object.remarks }}</p>
                            <p v-if="!object.remarks">{{ $t('system.no_results') }}</p>
                          </b-form-group>
                        </div>
                        <div v-if="object.code == 'control_assessment' || object.code == 'risk_assessment'">
                          <b-form-group v-if="object.users && object.users.length">
                            <label qid="create-p-users-label">
                              {{ $t('tasks.table.task_assignee') }}
                            </label>
                            <div class="mb-1" v-for="(user, user_index) in object.users" :key="user_index">
                              <span qid="profile-photo">
                                <Avatar :object="user" size="xs"></Avatar>
                                {{ user.name }}
                              </span>
                            </div>
                          </b-form-group>
                          <b-form-group v-if="object.status">
                            <label qid="create-p-users-label">
                              {{ $t('tasks.table.task_status') }}
                            </label>
                            <p v-if="object.status">
                              <span v-if="object.status === 'completed'" >
                                <font-awesome-icon icon="check-circle" class="icon-success"/>
                              </span>
                              <span v-if="object.status === 'pending'" class="pr-2">
                                <font-awesome-icon
                                    icon="exclamation-triangle"
                                    class="risk-6"
                                />
                              </span>
                              {{ $t('system.' + $options.filters.slugify(object.status)) }}
                            </p>
                          </b-form-group>
                          <b-form-group v-if="object.response">
                            <label qid="create-p-users-label">
                              {{ $t('tasks.modal.response_label') }}
                            </label>
                            <div v-if="object.response">
                              <span v-if="object.response_type == 'Datepicker'">
                                <font-awesome-icon icon="calendar-alt" class="mr-2"/>
                                {{ object.response | formatDate }}
                              </span>
                              <span v-else>
                                <font-awesome-icon icon="check-circle" class="icon-success" v-if="getIcon(object) == 'Positive'"/>
                                <font-awesome-icon icon="times-circle" class="icon-danger" v-if="getIcon(object) == 'Negative'"/>
                                <font-awesome-icon icon="info-circle" class="icon-info" v-if="getIcon(object) == 'Neutral'"/>
                                <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="getIcon(object) == 'Very low'"/>
                                <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="getIcon(object) == 'Low'"/>
                                <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="getIcon(object) == 'High'"/>
                                <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="getIcon(object) == 'Very high'"/>
                                {{ object.response }}
                              </span>
                            </div>
                          </b-form-group>
                          <b-form-group v-if="object.control_description">
                            <label qid="create-p-users-label">
                              {{ $t('tasks.modal.control_description_label') }}
                            </label>
                            <p class="text-formatted">{{ object.control_description }}</p>
                          </b-form-group>
                          <b-form-group v-if="object.performed_text">
                            <label qid="create-p-users-label">
                              {{ $t('tasks.modal.performed_test_label') }}
                            </label>
                            <p class="text-formatted">{{ object.performed_text }}</p>
                          </b-form-group>
                          <b-form-group v-if="object.remarks">
                            <label qid="create-p-users-label">
                              {{ $t('tasks.modal.remarks_label') }}
                            </label>
                            <p class="text-formatted">{{ object.remarks }}</p>
                          </b-form-group>
                          <b-form-group v-if="object.data_users && object.data_users.length">
                            <label qid="create-p-users-label">
                              {{ $t('tasks.table.data_collection_assignee') }}
                            </label>
                            <div class="mb-1" v-for="(user, data_user_index) in object.data_users" :key="data_user_index">
                              <span qid="profile-photo">
                                <Avatar :object="user" size="xs"></Avatar>
                                {{ user.name }}
                              </span>
                            </div>
                          </b-form-group>
                          <b-form-group v-if="object.data_remarks">
                            <label qid="create-p-users-label">
                              {{ $t('tasks.modal.comment_label') }}
                            </label>
                            <p class="text-formatted">{{ object.data_remarks }}</p>
                          </b-form-group>
                          <b-form-group>
                            <label qid="create-p-users-label">
                              {{ $t('maintenance.policies.create.attachments') }}
                            </label>
                            <p>
                              <span v-if="(object.media && object.media.length) || (object.links && object.links.length)">
                                {{ $t('system.yes') }}
                              </span>
                              <span v-else>
                                {{ $t('system.no') }}
                              </span>
                            </p>
                          </b-form-group>
                        </div>
                      </b-popover>
                      <td>
                        <a href="#" v-if="object.code == 'business_risk_assessment' || object.code == 'privacy_risk_assessment' || object.code == 'system_impact_assessment'" @click="navigateToContent(object, 'model')">
                          <span class="mr-2 mt-2" v-if="object.model">
                            <font-awesome-icon icon="desktop" size="lg" class="mr-2"/>
                          </span>
                          <span v-if="object.model">
                            {{ object.model.name}}
                          </span>
                          <span class="text-muted" v-if="!object.model">
                            {{ $t('tasks.modal.content_deleted') }}
                          </span>
                        </a>
                        <span v-else>
                          <span v-if="activity.type && (activity.type == 'update' || activity.type == 'validation')">
                            {{ $t('tasks.values.' + $options.filters.slugify(object.title)) }}
                          </span>
                          <span v-else>
                            {{ object.full_title }}
                          </span>
                        </span>
                      </td>

                      <td>
                        <span v-if="object.status === 'completed'" >
                            <font-awesome-icon icon="check-circle" class="icon-success"/>
                            {{ $t('system.task_completed') }}
                        </span>
                        <span v-if="object.status === 'pending'" class="pr-2">
                          <span v-if="!object.data_collection_on" >
                              <font-awesome-icon
                                      icon="exclamation-triangle"
                                      class="risk-6"
                              />
                              {{ $t('system.in_progress') }}
                          </span>
                          <span v-if="object.data_collection_on && object.data_status !== 'completed'" >
                              <font-awesome-icon
                                      icon="exclamation-triangle"
                                      class="risk-6"
                              />
                              {{ $t('system.documentation_in_progress') }}
                          </span>
                          <span v-if="object.data_collection_on && object.data_status === 'completed'" >
                              <font-awesome-icon
                                      icon="info-circle"
                                      class="icon-info"
                              />
                              {{ $t('system.documentation_completed') }}
                          </span>
                        </span>

                      </td>
                      <td>
                        <span v-if="object.add_response == 'No' || !object.add_response">
                          {{ $t('system.n_a') }}
                        </span>
                        <span v-else-if="object.response">
                          <span v-if="object.response_type == 'Datepicker'">
                            <font-awesome-icon icon="calendar-alt" class="mr-2"/>
                            {{ object.response | formatDate }}
                          </span>
                          <span v-else>
                            <font-awesome-icon icon="check-circle" class="icon-success" v-if="getIcon(object) == 'Positive'"/>
                            <font-awesome-icon icon="times-circle" class="icon-danger" v-if="getIcon(object) == 'Negative'"/>
                            <font-awesome-icon icon="info-circle" class="icon-info" v-if="getIcon(object) == 'Neutral'"/>
                            <font-awesome-icon icon="exclamation-triangle" :class="'risk-1'" v-if="getIcon(object) == 'Very low'"/>
                            <font-awesome-icon icon="exclamation-triangle" :class="'risk-3'" v-if="getIcon(object) == 'Low'"/>
                            <font-awesome-icon icon="exclamation-triangle" :class="'risk-9'" v-if="getIcon(object) == 'High'"/>
                            <font-awesome-icon icon="exclamation-triangle" :class="'risk-16'" v-if="getIcon(object) == 'Very high'"/>
                            {{ object.response }}
                          </span>
                        </span>
                        <span v-else>
                          <span v-if="object.status === 'pending'">
                            <span class="pr-2">
                              <font-awesome-icon
                                  icon="exclamation-triangle"
                                  class="risk-6"
                              />
                            </span>
                            {{ $t('system.' + $options.filters.slugify(object.status)) }}
                          </span>
                        </span>
                      </td>
                      <td>
                        <span v-if="object.status === 'completed'">
                          <span v-if="object.qa_status === 'accepted'" >
                              <font-awesome-icon icon="check-circle" class="icon-success"/>
                              {{ $t('system.accepted') }}
                          </span>
                          <span v-else-if="object.qa_status === 'rejected'" class="pr-2">
                                <font-awesome-icon icon="times-circle" class="icon-danger"/>
                                {{ $t('system.rejected') }}
                          </span>
                          <span v-else>
                              <font-awesome-icon
                                      icon="exclamation-triangle"
                                      class="risk-6"
                              />
                              {{ $t('system.in_progress') }}
                          </span>
                        </span>
                        <span v-if="object.status !== 'completed'" >
                            <span v-if="object.qa_status === 'accepted'" >
                                <font-awesome-icon icon="check-circle" class="icon-success"/>
                                {{ $t('system.accepted') }}
                            </span>
                            <span v-else-if="object.qa_status === 'rejected'" class="pr-2">
                                  <font-awesome-icon icon="times-circle" class="icon-danger"/>
                                  {{ $t('system.rejected') }}
                            </span>
                            <span v-else>
                                <font-awesome-icon
                                        icon="info-circle"
                                        class="icon-info"
                                />
                              {{ $t('system.waiting') }}
                            </span>

                        </span>


                      </td>
                      <td class="text-right">
                        <span class="btn btn-edit"
                              qid="delete-s-modal"
                              @click="showEditUsersModal(object, 'default')"
                              v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                        >
                          <font-awesome-icon icon="user-tag" />
                        </span>
                        <span class="btn btn-edit"
                              qid="delete-s-modal"
                              @click="showViewTaskModal(object)"
                              v-if="object.status === 'completed' && (activity.type == 'security_measure' || activity.type == 'control' || activity.type == 'risk_assessment' || activity.type == 'bia_assessment')"
                        >
                          <font-awesome-icon icon="eye" />
                        </span>
                        <AssessmentSingleTaskModal
                            :task="object"
                            v-if="object.model && activity.type == 'bia_assessment' && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                            @reloadContent="reloadTasks"
                        ></AssessmentSingleTaskModal>

                        <AssessmentGlobalSingleTaskModal
                            :task="object"
                            :controlObjective="selectedCategory"
                            v-if="object.model && object.code != 'update_measures' && (activity.type == 'risk_assessment' || activity.type == 'control_assessment') && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                            @reloadContent="reloadTasks"
                        ></AssessmentGlobalSingleTaskModal>
                        <AssessmentGlobalMeasureSingleTaskModal
                            :task="object"
                            v-if="object.model && object.code == 'update_measures' && activity.type == 'control_assessment' && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                            @reloadContent="reloadTasks"
                        ></AssessmentGlobalMeasureSingleTaskModal>
                        <RiskSingleTaskModal
                            :task="object"
                            @reloadContent="reloadTasks"
                            v-if="object.model && object.scope && (activity.type == 'security_measure' || activity.type == 'control') && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                        ></RiskSingleTaskModal>
                        <UpdateSingleTaskModal
                            :task="object"
                            @reloadContent="reloadTasks"
                            v-if="object.model && (activity.type == 'update') && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                        ></UpdateSingleTaskModal>
                        <ValidationSingleTaskModal
                            :task="object"
                            @reloadContent="reloadTasks"
                            v-if="object.model && (activity.type == 'validation') && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                        ></ValidationSingleTaskModal>
                        <span v-if="activity.type == 'security_measure' || activity.type == 'control'">
                          <b-button
                              variant="delete"
                              @click="showConfirmDeleteModal(object)"
                              v-if="object.scope && object.model && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                              qid="delete-s-cancel-button"
                          >
                            <font-awesome-icon icon="trash-alt"/>
                          </b-button>
                        </span>
                        <span v-else>
                          <b-button
                              variant="delete"
                              @click="showConfirmDeleteModal(object)"
                              v-if="object.model && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                              qid="delete-s-cancel-button"
                          >
                            <font-awesome-icon icon="trash-alt"/>
                          </b-button>
                        </span>
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-if="open_tasks && open_tasks.length && !loadingTasks && selectedCategory && !selectedCategory.id">
                    <h5 class="text-center mt-3">
                      {{ $t('tasks.table.select_category') }}
                    </h5>
                  </tbody>

                  <tbody v-if="open_tasks && !open_tasks.length && !loadingTasks">
                    <tr qid="no-results-row" class="text-center">
                      <td colspan="10">
                        <h5 class="mt-3">
                          {{ $t('system.no_results') }}
                        </h5>
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-if="loadingTasks && !open_tasks.length">
                    <tr qid="no-results-row-rr" class="text-center">
                      <td colspan="10">
                        <div class="mt-2 mb-4">
                          <div class="swing-container">
                            <div class="swing">
                              <div class="swing-l"></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div class="swing-r"></div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="mt-3 pagination row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="float-left">
                        <span class="text-muted pagination-counter">
                          {{ $t('pagination.entries_per_page') }}:
                        </span>
                      </div>
                      <div class="float-left">
                        <multiselect
                            v-model="tasksPerPage"
                            :options="perPageOptions"
                            track-by="value"
                            label="value"
                            :select-label="$t('system.dropdown_select_label')"
                            :selected-label="$t('system.dropdown_selected_label')"
                            :deselect-label="$t('system.dropdown_deselect_label')"
                            qid="create-org-form-organization-level"
                            @select="tasksPerPageChanged"
                            :placeholder="tasksSelectedPerPage"
                            @remove="tasksPerPageRemoved"
                        ></multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <b-pagination
                        class="float-right"
                        v-model="tasksCurrentPage"
                        :total-rows="tasksRows"
                        :per-page="tasksSelectedPerPage"
                        aria-controls="my-table-1"
                        @change="tasksPageChanged"
                        limit="1"
                    >
                      <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
                      <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
                      <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
                      <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
                      <div slot="ellipsis-text">
                        <b-spinner small type="grow"></b-spinner>
                        <b-spinner small type="grow"></b-spinner>
                        <b-spinner small type="grow"></b-spinner>
                      </div>
                      <span class="text-muted" slot="page" slot-scope="{ page, active }">
                        <span v-if="active">{{getTasksPerPage}}</span>
                      </span>
                    </b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


    <!-- Re-assign modal -->
    <b-modal ref="re-assign" modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideEditUsersModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="re-assign-title">
            {{ $t('tasks.re_assign.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="re-assign-error"
      >
        {{ error }}
      </b-alert>

      <b-form @submit="reassignFormSubmit" qid="re-assign-form" v-if="selectedTask && selectedTask.model">
        <div class="form-container">
          <b-form-group>
            <label qid="re-assign-users-label">
              {{ $t('tasks.re_assign.users_label') }}
            </label>

            <div class="multiselect-row">
              <multiselect
                  v-model="selectedAssignUsers"
                  :options="allUsers"
                  :multiple="true"
                  :taggable="false"
                  :disabled="buttonDisabled"
                  track-by="slug"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  :placeholder="$t('tasks.re_assign.users_placeholder')"
                  label="name"
                  qid="re-assign-users-select"
              >
                <template slot="tag" slot-scope="props">
                  <span class="mr-3 user-tag">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-2">{{ props.option.name }}</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
              </multiselect>

              <UserInviteModal
                    :buttonType="'small'"
                    :buttonDisabled=buttonDisabled
                    @reloadUserList="reloadUserList"
              ></UserInviteModal>
            </div>
          </b-form-group>
          <b-form-group v-if="itemObject.data_collection_on">
            <label qid="re-assign-users-label">
              {{ $t('tasks.re_assign.data_collection_users_label') }}
            </label>

            <div class="multiselect-row">
              <multiselect
                  v-model="selectedDataAssignUsers"
                  :options="allUsers"
                  :multiple="true"
                  :taggable="false"
                  :disabled="buttonDisabled"
                  track-by="slug"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  :placeholder="$t('tasks.re_assign.users_placeholder')"
                  label="name"
                  qid="re-assign-users-select"
              >
                <template slot="tag" slot-scope="props">
                  <span class="mr-3 user-tag">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-2">{{ props.option.name }}</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
              </multiselect>

              <UserInviteModal
                    :buttonType="'small'"
                    :buttonDisabled=buttonDisabled
                    @reloadUserList="reloadUserList"
              ></UserInviteModal>
            </div>
          </b-form-group>
        </div>
      </b-form>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class="mt-3"
                            variant="secondary"
                            @click="hideEditUsersModal"
                            qid="re-assign-cancel-button"
                  >
                    {{ $t('projects.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="mt-3 ml-3"
                            variant="success"
                            @click="reassignFormSubmit"
                            :disabled="buttonDisabled"
                            qid="re-assign-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('projects.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Update default assignees -->
    <b-modal ref="update-default-assignees"
             hide-footer
             modal-class="task-modal"
             @hide="hideDefaultAssigneesModal">

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideDefaultAssigneesModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-default-assignees-title">
            {{ $t('projects.create.task_assignees') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>

      <b-form @submit="updateDefaultAssigneesFormSubmit" qid="create-p-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('projects.create.default_task_assignees') }}
            </label>

            <div class="multiselect-row">
              <multiselect
                  v-model="selectedDefaultTaskUsers"
                  :options="userList"
                  :multiple="true"
                  :taggable="false"
                  :disabled="buttonDisabled"
                  @search-change="loadUsers"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  track-by="slug"
                  :placeholder="$t('projects.create.default_task_assignees')"
                  label="name"
                  qid="create-p-users-select"
              >
                <template slot="tag" slot-scope="props">
                  <span class="mr-3 user-tag">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-2">{{ props.option.name }}</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
              </multiselect>

              <UserInviteModal
                    :buttonType="'small'"
                    :buttonDisabled=buttonDisabled
                    @reloadUserList="reloadUserList"
              ></UserInviteModal>
            </div>

            <b-form-checkbox
              v-model="applyOnTasks"
              v-if="activity.control_type != 'continuous'"
              name="checkbox-disable"
              :value="1"
              :unchecked-value="0"
            >
              Apply for all tasks
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="pt-3 mb-0">
            <label qid="create-p-users-label">
              {{ $t('projects.create.data_collection_task_assignees') }}
            </label>

            <div class="multiselect-row">
              <multiselect
                  v-model="selectedDefaultDataCollectionUsers"
                  :options="userList"
                  :multiple="true"
                  :taggable="false"
                  @search-change="loadUsers"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  track-by="slug"
                  :placeholder="$t('projects.create.data_collection_task_assignees')"
                  label="name"
                  qid="create-p-users-select"
              >
                <template slot="tag" slot-scope="props">
                  <span class="mr-3 user-tag">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-2">{{ props.option.name }}</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
              </multiselect>

              <UserInviteModal
                    :buttonType="'small'"
                    :buttonDisabled=buttonDisabled
                    @reloadUserList="reloadUserList"
              ></UserInviteModal>
            </div>

            <b-form-checkbox
              v-model="applyOnDataCollectionTasks"
              v-if="activity.control_type != 'continuous'"
              name="checkbox-disable"
              :value="1"
              :unchecked-value="0"
            >
              Apply for all tasks
            </b-form-checkbox>
          </b-form-group>
        </div>

        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="text-right">
              <div class="text-right">
                <b-button class="mt-2"
                          variant="secondary"
                          @click="hideDefaultAssigneesModal"
                          qid="update-default-assignees-cancel-button"
                >
                  {{ $t('projects.create.cancel') }}
                </b-button>
                <b-button type="submit"
                          class="mt-2 ml-3"
                          variant="success"
                          :disabled="buttonDisabled"
                          qid="update-default-assignees-submit-button"
                >
                  <span v-if="buttonDisabled" class="mr-1">
                    <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                  </span>
                  {{ $t('projects.create.submit') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>


    <!-- Delete task modal template -->
    <b-modal ref="delete-task" modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideConfirmDeleteModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="delete-task-title">
            {{ $t('activities.system.delete.title') }}
          </h5>
        </div>
      </template>

      <div class="mb-2 mt-3" style="font-size:18px;" v-if="itemToDelete && itemToDelete.id">
        <div>
          {{ $t('activities.system.delete.description') }}
          <span class="text-bold">
            {{ itemToDelete.full_title }}
          </span>
        </div>
        <div class="mt-2">
          {{ $t('maintenance.measures.delete.are_you_sure') }}
        </div>
      </div>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <span class="btn btn-secondary cursor-pointer"
                        @click="hideConfirmDeleteModal"
                        qid="delete-task-cancel-button"
                  >
                    {{ $t('maintenance.measures.delete.cancel') }}
                  </span>
                  <b-button type="submit"
                            class="ml-3"
                            variant="danger"
                            @click="confirmDeleteS(itemToDelete)"
                            :disabled="buttonDisabled"
                            qid="delete-task-submit-button"
                  >
                    {{ $t('maintenance.measures.delete.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>


    <!-- Add from template modal -->
    <b-modal ref="add-task"
             hide-footer
             modal-class="task-modal"
             @hide="hideAddTaskModal">

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideAddTaskModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-p-title">
            {{ $t('tasks.add_task.modal_title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>

      <b-form @submit="addTaskFormSubmit" qid="create-p-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('tasks.add_task.select_question_label') }}
            </label>

            <div class="multiselect-row">
              <multiselect
                  v-model="selectedQuestion"
                  :options="questions"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  :placeholder="$t('tasks.add_task.select_question_placeholder')"
                  label="name"
                  qid="create-p-users-select"
              >
              <template slot="singleLabel" slot-scope="props">
                <span v-if="props.option.reference">
                  <span>{{ props.option.reference }}: </span>
                </span>
                {{ props.option.name }}
              </template>
              <template slot="option" slot-scope="props">
                <span v-if="props.option.reference">
                  <span>{{ props.option.reference }}: </span>
                </span>
                {{ props.option.name }}
              </template>
              </multiselect>

              <b-button
                  class="btn btn-secondary float-right"
                  @click="showCreateQForm()"
                  v-b-tooltip.hover
                  :title="$t('maintenance.control_objective.add_new')"
              >
                <font-awesome-icon icon="plus-circle"/>
              </b-button>
            </div>
          </b-form-group>
        </div>

        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="text-right">
              <div class="text-right">
                <b-button class="mt-2"
                          variant="secondary"
                          @click="hideAddTaskModal"
                          qid="create-p-cancel-button"
                >
                  {{ $t('projects.create.cancel') }}
                </b-button>
                <b-button type="submit"
                          class="mt-2 ml-3"
                          variant="success"
                          :disabled="buttonDisabled"
                          qid="create-p-submit-button"
                >
                  <span v-if="buttonDisabled" class="mr-1">
                    <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                  </span>
                  {{ $t('projects.create.submit') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>


    <!-- Create new task modal -->
    <b-modal ref="add-question-1"
             @hide="hideCreateQModal"
             modal-class="task-modal"
             hide-footer>

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideCreateQModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-q-title">
            {{ $t('maintenance.questions.create.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-q-error"
      >
        {{ error }}
      </b-alert>

      <div class="form-container">
        <div class="mb-3">
          <h5 class="mb-2">
            {{ $t('maintenance.questions.create.question_title') }}
          </h5>
          <span class="text-muted">
              {{ $t('maintenance.questions.create.question_description') }}
            </span>
        </div>

        <b-form-group>
          <label qid="create-q-name-label">
            {{ $t('maintenance.questions.create.name_label') }}
            <span class="text-red">*</span>
          </label>
          <b-form-input
                  qid="create-q-form-name"
                  type="text"
                  v-model="form.name"
                  required
                  :maxLength="255"
                  :placeholder="$t('maintenance.questions.create.name_placeholder')"
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <label qid="create-q-reference-label">
            {{ $t('maintenance.questions.create.reference_label') }}
          </label>
          <b-form-input
                  qid="create-q-form-reference"
                  type="text"
                  v-model="form.reference"
                  :maxLength="255"
                  v-on:input="debounceInput"
                  :class="{'is-invalid': referenceInvalid}"
                  :placeholder="$t('maintenance.questions.create.reference_placeholder')"
          ></b-form-input>
          <b-form-invalid-feedback
                  v-if="referenceInvalid"
                  qid="pasword-requirement-notice"
                  class="d-block"
          >
            {{ $t('maintenance.questions.create.reference_error') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <label qid="create-assessment-control-objective-label">
            {{ $t('maintenance.control_objective.title') }}<span class="mandatory"> *</span>
          </label>
          <div class="multiselect-row">
            <multiselect
                    v-model="selectedControlObjective"
                    :options="controlObjectives"
                    :placeholder="$t('maintenance.control_objective.title_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    required
                    :allow-empty="false"
                    @search-change="loadCO"
                    track-by="id"
                    qid="create-assessment-control-form-control-objective"
            >
              <template slot="singleLabel" slot-scope="props">
                <font-awesome-icon icon="book"/>
                <span class="ml-2">
                    {{ props.option.name }}
                  </span>
              </template>
              <template slot="option" slot-scope="props">
                <font-awesome-icon icon="book"/>
                <span class="ml-2">
                    {{ props.option.name }}
                  </span>
              </template>
            </multiselect>
            <!-- <b-button
                    class="btn btn-secondary float-right"
                    @click="showCreateControlObjectiveModal()"
                    qid="control-objective-create-button-fc-modal"
                    v-if="isGranted(permissions.CONTROL_OBJECTIVE_CREATE) && (!getClient.parent || (getClient.parent && object.partition))"
                    v-b-tooltip.hover
                    :title="$t('maintenance.control_objective.add_new')"
            >
              <font-awesome-icon icon="plus-circle"/>
            </b-button> -->
          </div>
          <p v-if="form.control_objective && form.control_objective.name">
            <font-awesome-icon icon="book"/>
            {{ form.control_objective.name }}
          </p>
        </b-form-group>
      </div>
      <div class="proxy-footer">
        <div class="progress-indicator-footer col text-center pt-2 pb-1">
          <ul class="progress-indicator pr-5 pl-5 mb-1">
            <li class="progress-active">1</li>
            <li class="progress-uncompleted">2</li>
            <li class="progress-uncompleted">3</li>
            <li class="progress-uncompleted">4</li>
            <li class="progress-uncompleted">5</li>
          </ul>

          <ul class="progress-indicator-text pr-4 pl-4">
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.naming') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.framework_controls') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.documentation_collection_task') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.assessment_task') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.complete') }}</li>
          </ul>
        </div>
        <div class="alert alert-menu-color color-secondary mb-0" role="alert">
          <div class="row">
            <div class="col-sm-6">

            </div>
            <div class="col-sm-6">
              <div class="text-right">
                <b-button class=""
                          variant="secondary"
                          @click="hideCreateQModal"
                          qid="create-q-cancel-button"
                >
                  {{ $t('maintenance.questions.create.cancel') }}
                </b-button>
                <b-button @click="showCreateQForm2('forward')"
                          class="ml-3"
                          variant="success"
                          :disabled="buttonDisabled"
                          qid="create-q-submit-button"
                >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                  {{ $t('maintenance.questions.create.next') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal ref="add-question-2"
             @hide="hideCreateQModal"
             modal-class="task-modal"
             hide-footer>

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideCreateQModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-q-title">
            {{ $t('maintenance.questions.create.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-q-error"
      >
        {{ error }}
      </b-alert>

      <div class="form-container">
        <div class="mb-3">
          <h5 class="mb-2">
            {{ $t('maintenance.questions.create.framework_controls_title') }}
          </h5>
          <span class="text-muted">
              {{ $t('maintenance.questions.create.framework_controls_description') }}
            </span>
        </div>

        <div class="">
          <div class="row">
            <div class="col-sm-6">
            </div>
            <div class="col-sm-6 text-right">
              <button class="btn btn-success"
                      qid="frameworkControl-create-button"
                      @click="showAddFrameworkControlModal"
              >
                <font-awesome-icon icon="plus"/> {{ $t('generic_controls.framework_controls.create_button') }}
              </button>
            </div>
          </div>
          <table class="table table-responsive-md table-list-secondary">
            <thead>
            <tr>
              <th scope="col">
                {{ $t('generic_controls.framework_controls.name') }}
              </th>
              <th width="100"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in selectedFrameworkControls" :key="'table-row-' + index" :id="'table-row-' + index">
              <b-popover
                      :target="'table-row-' + index"
                      boundary="viewport"
                      triggers="hover"
                      placement="topleft"
                      :delay="{ show: 500, hide: 0 }"
              >
                <div v-if="item.active_framework && item.active_framework.name" class="mt-2">
                      <span class="text-bold">
                        {{ $t('maintenance.questions.create.active_framework_name_label') }}:
                      </span>
                  <br>
                  {{ item.active_framework.name }}
                  <br>
                </div>
                <div v-if="item.control_objective && item.control_objective.name" class="mt-2">
                      <span class="text-bold">
                        {{ $t('maintenance.questions.create.control_objective_name_label') }}:
                      </span>
                  <br>
                  {{ item.control_objective.name }}
                  <br>
                </div>
                <div v-if="item.control_objective && item.control_objective.description" class="mt-2">
                      <span class="text-bold">
                        {{ $t('maintenance.questions.create.control_objective_description_label') }}:
                      </span>
                  <br>
                  {{ item.control_objective.description | truncate(200)}}
                  <br>
                </div>
                <div v-if="item && item.name" class="mt-2">
                      <span class="text-bold">
                        {{ $t('maintenance.framework_control.table.name') }}:
                      </span>
                  <br>
                  {{ item.name }}
                  <br>
                </div>
                <div v-if="item.description" class="mt-2">
                      <span class="text-bold">
                        {{ $t('maintenance.framework_control.create.description_label') }}:
                      </span>
                  <br>
                  {{ item.description | truncate(200)}}
                  <br>
                </div>
              </b-popover>
              <td scope="row">
                    <span v-if="item.active_framework && item.active_framework.name" class="text-muted">
                      {{ item.active_framework.name }}
                    </span>
                <br>
                <span>
                      {{ item.full_name }}
                    </span>
              </td>
              <td>
                <button
                        class="btn btn-delete float-right"
                        qid="delete-framework_control-modal"
                        @click="removeFrameworkControlItem(index)"
                >
                  <font-awesome-icon icon="trash-alt"/>
                </button>
              </td>
            </tr>
            <tr v-if="!selectedFrameworkControls.length" qid="no-results-row" class="text-center">
              <td colspan="10">
                <h5 class="mt-3">
                  {{ $t('system.no_results') }}
                </h5>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="proxy-footer">
        <div class="progress-indicator-footer col text-center pt-2 pb-1">
          <ul class="progress-indicator pr-5 pl-5 mb-1">
            <li class="progress-completed">1</li>
            <li class="progress-active">2</li>
            <li class="progress-uncompleted">3</li>
            <li class="progress-uncompleted">4</li>
            <li class="progress-uncompleted">5</li>
          </ul>

          <ul class="progress-indicator-text pr-4 pl-4">
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.naming') }}</li>
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.framework_controls') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.documentation_collection_task') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.assessment_task') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.complete') }}</li>
          </ul>
        </div>
        <div class="alert alert-menu-color color-secondary mb-0" role="alert">
          <div class="row">
            <div class="col-sm-6">

            </div>
            <div class="col-sm-6">
              <div class="text-right">
                <b-button class=""
                          variant="secondary"
                          @click="showCreateQForm1('back')"
                          qid="create-q-cancel-button"
                >
                  {{ $t('maintenance.questions.create.back') }}
                </b-button>
                <b-button @click="showCreateQForm3('forward')"
                          class="ml-3"
                          variant="success"
                          :disabled="buttonDisabled"
                          qid="create-q-submit-button"
                >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                  {{ $t('maintenance.questions.create.next') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal ref="add-question-3"
             @hide="hideCreateQModal"
             modal-class="task-modal"
             hide-footer>

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideCreateQModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-q-title">
            {{ $t('maintenance.questions.create.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-q-error"
      >
        {{ error }}
      </b-alert>

      <div class="form-container">
        <div class="mb-3">
          <h5 class="mb-2">
            {{ $t('maintenance.questions.create.question_data_collection_title') }}
          </h5>
          <span class="text-muted">
              {{ $t('maintenance.questions.create.question_data_collection_description') }}
            </span>
        </div>

        <div v-if="assessment.type == 'risk'">
          <h5>
            Data collection step is available only for global assessments. Press next to continue.
          </h5>
        </div>

        <div class="form-group" v-if="assessment.type != 'risk'">
          <label qid="create-q-data_collection-radio-label">
            {{ $t('maintenance.questions.create.data_collection_label') }}
          </label>
          <b-form-radio-group
                  v-model="data_collection_on"
                  :options="dataCollectionOptions"
                  @change="lockSwitch(data_collection_on)"
                  qid="create-q-data_collection-radio-option"
          ></b-form-radio-group>
        </div>
        <div v-if="data_collection_on && assessment.type != 'risk'">
          <b-form-group>
            <label qid="create-q-data_collection-description-label">
              {{ $t('maintenance.questions.create.data_collection_description_label') }}
              <span class="text-red">*</span>
            </label>
            <b-form-textarea
                    qid="create-q-data_collection-form-description"
                    v-model="form.data_collection_description"
                    rows="6"
                    max-rows="6"
                    required
                    :placeholder="$t('maintenance.questions.create.data_collection_description_placeholder')"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group>
            <div v-show="showDropActive" class="drop-active">
              <h3>Drop files to upload</h3>
            </div>
            <label for=""> {{ $t('maintenance.questions.create.data_collection_attachment_label') }}</label>
            <div class="form-control auto-height pt-3 pb-3">
              <div class="text-center">
                <p>
                  Drag and drop files in here or
                  <file-upload
                          ref="uploader"
                          v-model="data_collection_files"
                          :drop="true"
                          :multiple="true"
                          input-id="uploader"
                          name="uploader"
                          @dropActive="showDropActiveFunction"
                          class="text-success cursor-pointer"
                  >
                    click here
                  </file-upload>
                  to upload files you wish to display together with the description. You may also
                  <span class="text-success cursor-pointer" @click="showLinksSection = true">add links</span>
                </p>
              </div>
              <p v-for="(file, index) in data_collection_files" :key="index">
                  <span class="float-right text-muted cursor-pointer"
                        @click="removeDataMediaItem(index)"
                        qid="create-q-data_collection-link-remove"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </span>
                {{file.name}}
              </p>
            </div>
          </b-form-group>

          <b-form-group v-if="showLinksSection">
            <label qid="create-q-data_collection-link-label">
              {{ $t('links.label') }}
            </label>
            <p v-for="(link, index) in form.data_collection_links" :key="index">
                <span class="float-right text-muted cursor-pointer"
                      @click="removeDataLinkItem(index)"
                      qid="create-q-data_collection-link-remove"
                >
                  <font-awesome-icon icon="trash-alt" />
                </span>
              <a :href="link.url" target="_blank" qid="create-q-data_collection-link-item">{{ link.name ? link.name : link.url }}</a>
            </p>
            <div>
              <label class="mt-2">{{ $t('system.link') }}</label>
              <b-form-input
                      type="text"
                      aria-describedby="inputGroup"
                      v-model="inputLinkDataCollection"
                      :placeholder="$t('system.link')"
                      qid="create-q-data_collection-links"
              ></b-form-input>
              <label class="mt-2">{{ $t('table.name') }}</label>
              <b-form-input
                      type="text"
                      aria-describedby="inputGroup"
                      v-model="inputLinkNameDataCollection"
                      :placeholder="inputLinkDataCollection ? inputLinkDataCollection : $t('table.name')"
                      qid="create-q-data_collection-links"
              ></b-form-input>
            </div>
            <span class="btn btn-link cursor-pointer float-right"
                  @click="handleDataCollectionLink"
                  qid="create-q-data_collection-add-link"
            >
                + {{ $t('system.add_link') }}
              </span>
          </b-form-group>

          <b-form-group>
            <label qid="create-q-data_collection-description-label">
              {{ $t('maintenance.questions.create.data_collection_description_two_label') }}
              <span class="text-red">*</span>
            </label>
            <b-form-textarea
                    qid="create-q-data_collection-form-description"
                    v-model="form.data_collection_description_two"
                    rows="6"
                    max-rows="6"
                    required
                    :placeholder="$t('maintenance.questions.create.data_collection_description_two_placeholder')"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group>
            <div v-show="showDropActive" class="drop-active">
              <h3>Drop files to upload</h3>
            </div>

            <label> {{ $t('maintenance.questions.create.data_collection_two_attachment_label') }}</label>

            <div class="form-control auto-height pt-3 pb-3">
              <div class="text-center">
                <p>
                  Drag and drop files in here or
                  <file-upload
                          ref="uploaderTwo"
                          v-model="data_collection_two_files"
                          :drop="true"
                          :multiple="true"
                          input-id="uploaderTwo"
                          name="uploaderTwo"
                          @dropActive="showDropActiveFunction"
                          class="text-success cursor-pointer"
                  >
                    click here
                  </file-upload>
                  to upload files you wish to display together with the description. You may also
                  <span class="text-success cursor-pointer" @click="showLinksSectionTwo = true">add links</span>
                </p>
              </div>

              <p v-for="(file, media_index) in selectedTask.media" :key="media_index">
                <span v-if="file.collection_name == 'data_collection_two'">
                  <span class="float-right text-muted cursor-pointer"
                        @click="openDeleteAttachmentModal(file)"
                        qid="remove-media-item"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </span>
                  <span class="mr-3 float-right text-muted cursor-pointer"
                        @click="downloadMedia(file)"
                        qid="download-media"
                  >
                    <font-awesome-icon icon="cloud-download-alt" />
                  </span>
                  <a @click="downloadMedia(file)" qid="create-q-media-item">
                    {{ file.file_name }}
                  </a>
                </span>
              </p>

              <div v-if="selectedTask.links">
                <p v-for="(link, link_index) in getDataCollectionTwoLinks" :key="link_index">
                  <span class="float-right text-muted cursor-pointer"
                        @click="openDeleteLinkModal(link)"
                        qid="view-q-remove-link"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </span>
                  <a :href="link.url" target="_blank" qid="create-q-link-item">
                    {{ link.name ? link.name : link.url }}
                  </a>
                </p>
              </div>
            </div>
          </b-form-group>


          <b-form-group v-if="showLinksSectionTwo">
            <label qid="create-q-link-label">
              {{ $t('maintenance.third_parties.create.links_label') }}
            </label>

            <div>
              <label class="mt-2">{{ $t('system.link') }}</label>
              <b-form-input
                      type="text"
                      aria-describedby="inputGroup"
                      v-model="inputLinkDataCollection"
                      :placeholder="$t('system.link')"
                      qid="create-q-links"
              ></b-form-input>
              <label class="mt-2">{{ $t('table.name') }}</label>
              <b-form-input
                      type="text"
                      aria-describedby="inputGroup"
                      v-model="inputLinkNameDataCollection"
                      :placeholder="inputLinkDataCollection ? inputLinkDataCollection : $t('table.name')"
                      qid="create-q-links"
              ></b-form-input>
              <div class="text-right mt-2">
                <span class="btn btn-success cursor-pointer mr-0"
                      id="inputGroup"
                      @click="handleEditDataCollectionTwoLink"
                      qid="handle-link-save"
                >
                  {{ $t('system.save') }}
                </span>
              </div>
            </div>
          </b-form-group>


          <b-form-group>
            <label qid="create-q-pg-label">
              {{ $t('maintenance.questions.create.add_comment_label') }}
              <span class="text-red">*</span>
            </label>
            <multiselect
                    v-model="dataCollectionAddDescription"
                    :options="allowTypesShort"
                    :placeholder="$t('maintenance.questions.create.add_comment_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-q-form-pg"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-q-pg-label">
              {{ $t('maintenance.questions.create.add_attachments_label') }}
              <span class="text-red">*</span>
            </label>
            <multiselect
                    v-model="dataCollectionAddAttachments"
                    :options="allowTypes"
                    :placeholder="$t('maintenance.questions.create.add_attachments_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    qid="create-q-form-pg"
            ></multiselect>
          </b-form-group>
        </div>
      </div>
      <div class="proxy-footer">
        <div class="progress-indicator-footer col text-center pt-2 pb-1">
          <ul class="progress-indicator pr-5 pl-5 mb-1">
            <li class="progress-completed">1</li>
            <li class="progress-completed">2</li>
            <li class="progress-active">3</li>
            <li class="progress-uncompleted">4</li>
            <li class="progress-uncompleted">5</li>
          </ul>

          <ul class="progress-indicator-text pr-4 pl-4">
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.naming') }}</li>
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.framework_controls') }}</li>
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.documentation_collection_task') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.assessment_task') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.complete') }}</li>
          </ul>
        </div>
        <div class="alert alert-menu-color color-secondary mb-0" role="alert">
          <div class="row">
            <div class="col-sm-6">

            </div>
            <div class="col-sm-6">
              <div class="text-right">
                <b-button class=""
                          variant="secondary"
                          @click="showCreateQForm2('back')"
                          qid="create-q-data_collection-back-button"
                >
                  {{ $t('maintenance.questions.create.back') }}
                </b-button>
                <b-button @click="showCreateQForm4('forward')"
                          class="ml-3"
                          variant="success"
                          :disabled="buttonDisabled"
                          qid="create-q-data_collection-submit-button"
                >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                  {{ $t('maintenance.questions.create.next') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>


    <b-modal ref="add-question-4"
             @hide="hideCreateQModal"
             modal-class="task-modal"
             hide-footer>

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideCreateQModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-q-title">
            {{ $t('maintenance.questions.create.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-q-error"
      >
        {{ error }}
      </b-alert>

      <div class="form-container">
        <div class="mb-3">
          <h5 class="mb-2">
            {{ $t('maintenance.questions.create.question_description_title') }}
          </h5>
          <span class="text-muted">
              {{ $t('maintenance.questions.create.question_description_description') }}
            </span>
        </div>

        <b-form-group>
          <label qid="create-q-description-label">
            {{ $t('maintenance.questions.create.description_label') }}
            <span class="text-red">*</span>
          </label>
          <b-form-textarea
                  qid="create-q-form-description"
                  v-model="form.description"
                  rows="6"
                  max-rows="6"
                  required
                  :placeholder="$t('maintenance.questions.create.description_placeholder')"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group>
          <div v-show="showDropActive" class="drop-active">
            <h3>Drop files to upload</h3>
          </div>
          <label for=""> {{ $t('maintenance.questions.create.data_collection_attachment_label') }}</label>
          <div class="form-control auto-height pt-3 pb-3">
            <div class="text-center">
              <p>
                Drag and drop files in here or
                <file-upload
                        ref="uploaderData"
                        v-model="files"
                        :drop="true"
                        :multiple="true"
                        input-id="uploaderData"
                        name="uploaderData"
                        @dropActive="showDropActiveFunction"
                        class="text-success cursor-pointer"
                >
                  click here
                </file-upload>
                to upload files you wish to display together with the description. You may also
                <span class="text-success cursor-pointer" @click="showQuestionLinksSection = true">add links</span>
              </p>

            </div>

            <p v-for="(file, index) in files" :key="index">
                <span class="float-right text-muted cursor-pointer"
                      @click="removeMediaItem(index)"
                      qid="create-q-data_collection-link-remove"
                >
                  <font-awesome-icon icon="trash-alt" />
                </span>
              {{file.name}}
            </p>
          </div>
        </b-form-group>
        <b-form-group v-if="showQuestionLinksSection">
          <label qid="create-q-link-label">
            {{ $t('links.label') }}
          </label>
          <p v-for="(link, index) in form.links" :key="index">
              <span class="float-right text-muted cursor-pointer"
                    @click="removeLinkItem(index)"
                    qid="create-q-link-remove"
              >
                <font-awesome-icon icon="trash-alt" />
              </span>
            <a :href="link.url" target="_blank" qid="create-q-link-item">{{ link.name ? link.name : link.url }}</a>
          </p>
          <div>
            <label class="mt-2">{{ $t('system.link') }}</label>
            <b-form-input
                    type="text"
                    aria-describedby="inputGroup"
                    v-model="inputLink"
                    :placeholder="$t('system.link')"
                    qid="create-q-links"
            ></b-form-input>
            <label class="mt-2">{{ $t('table.name') }}</label>
            <b-form-input
                    type="text"
                    aria-describedby="inputGroup"
                    v-model="inputLinkName"
                    :placeholder="inputLink ? inputLink : $t('table.name')"
                    qid="create-q-links"
            ></b-form-input>
          </div>
          <span class="btn btn-link cursor-pointer float-right"
                @click="handleLink"
                qid="create-q-add-link"
          >
              + {{ $t('system.add_link') }}
            </span>
        </b-form-group>

        <b-form-group>
          <label qid="create-q-description-label">
            {{ $t('maintenance.questions.create.description_two_label') }}
            <span class="text-red">*</span>
          </label>
          <b-form-textarea
                  qid="create-q-form-description"
                  v-model="form.description_two"
                  rows="6"
                  max-rows="6"
                  required
                  :placeholder="$t('maintenance.questions.create.description_two_placeholder')"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group>
          <div v-show="showDropActive" class="drop-active">
            <h3>
              {{ $t('tasks.modal.drop_files_to_upload_label') }}</h3>
          </div>
          <label for=""> {{ $t('maintenance.questions.create.data_collection_two_attachment_label') }}</label>
          <div class="form-control auto-height pt-3 pb-3">
            <div class="text-center">
              <p>
                {{ $t('tasks.modal.drop_files_here_label') }}
                <file-upload
                        ref="uploaderDataTwo"
                        v-model="files_two"
                        :drop="true"
                        :multiple="true"
                        input-id="uploaderDataTwo"
                        name="uploaderDataTwo"
                        @dropActive="showDropActiveFunction"
                        class="text-success cursor-pointer"
                >
                  {{ $t('system.click_here') }}
                </file-upload>
                {{ $t('tasks.modal.upload_files_second_text') }}
                <span class="text-success cursor-pointer" @click="showQuestionTwoLinksSection = true">add links</span>
              </p>
            </div>

            <p v-for="(file, media_index) in selectedTask.media" :key="media_index">
                <span v-if="file.collection_name == 'question_two'">
                  <span class="float-right text-muted cursor-pointer"
                        @click="openDeleteAttachmentModal(file)"
                        qid="remove-media-item"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </span>
                  <span class="mr-3 float-right text-muted cursor-pointer"
                        @click="downloadMedia(file)"
                        qid="download-media"
                  >
                    <font-awesome-icon icon="cloud-download-alt" />
                  </span>
                  <a @click="downloadMedia(file)" qid="create-q-media-item">
                    {{ file.file_name }}
                  </a>
                </span>
            </p>

            <div v-if="selectedTask.links">
              <p v-for="(link, link_index) in selectedTask.links_two" :key="link_index">
                  <span class="float-right text-muted cursor-pointer"
                        @click="openDeleteLinkModal(link)"
                        qid="view-q-remove-link"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </span>
                <a :href="link.url" target="_blank" qid="create-q-link-item">
                  {{ link.name ? link.name : link.url }}
                </a>
              </p>
            </div>
          </div>
        </b-form-group>
        <b-form-group v-if="showQuestionTwoLinksSection">
          <label qid="create-q-link-label">
            {{ $t('maintenance.third_parties.create.links_label') }}
          </label>

          <div>
            <label class="mt-2">{{ $t('system.link') }}</label>
            <b-form-input
                    type="text"
                    aria-describedby="inputGroup"
                    v-model="inputLink"
                    :placeholder="$t('system.link')"
                    qid="create-q-links"
            ></b-form-input>
            <label class="mt-2">{{ $t('table.name') }}</label>
            <b-form-input
                    type="text"
                    aria-describedby="inputGroup"
                    v-model="inputLinkName"
                    :placeholder="inputLink ? inputLink : $t('table.name')"
                    qid="create-q-links"
            ></b-form-input>
            <div class="text-right mt-2">
                <span class="btn btn-success cursor-pointer mr-0"
                      id="inputGroup"
                      qid="handle-link-save"
                >
                  {{ $t('system.save') }}
                </span>
            </div>
          </div>
        </b-form-group>

        <b-form-group>
          <label qid="create-q-pg-label">
            {{ $t('maintenance.questions.create.add_response_label') }}
            <span class="text-red">*</span>
          </label>
          <multiselect
                  v-model="addResponse"
                  :options="allowTypes"
                  :placeholder="$t('maintenance.questions.create.add_response_placeholder')"
                  label="name"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="create-q-form-pg"
          ></multiselect>
        </b-form-group>

        <b-form-group v-if="addResponse && addResponse.value && addResponse.value != 'No'">
          <label qid="create-q-pg-label">
            {{ $t('maintenance.questions.create.type_label') }}
            <span class="text-red">*</span>
          </label>
          <multiselect
                  v-model="responseType"
                  :options="responseTypes"
                  group-values="items"
                  group-label="group"
                  :group-select="false"
                  :placeholder="$t('maintenance.questions.create.type_placeholder')"
                  label="name"
                  @select="handleType"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="create-q-form-pg"
          ></multiselect>
        </b-form-group>

        <b-form-group v-if="responseType && responseType.value == 'Dropdown' && addResponse && addResponse.value && addResponse.value != 'No'">
          <label qid="create-p-link-label">
            {{ $t('maintenance.questions.create.options_label') }}
            <span class="text-red">*</span>
          </label>
          <p v-for="(option, index) in selectedOptions" class="mt-1 mb-1 pb-3 pt-3" :key="index">
            <span class="float-right btn btn-delete"
                  @click="removeOptionItem(index, option)"
                  qid="create-p-link-remove"
            >
              <font-awesome-icon icon="trash-alt" />
            </span>

            <multiselect
                    v-model="option.value"
                    :options="answerTypes"
                    group-values="items"
                    group-label="group"
                    :group-select="false"
                    :placeholder="$t('maintenance.questions.create.type_placeholder')"
                    label="name"
                    :select-label="''"
                    :selected-label="''"
                    :deselect-label="''"
                    class="float-right mr-2"
                    style="width:220px;"
                    :allow-empty="false"
                    qid="create-p-form-pg"
            >
              <template slot="singleLabel" slot-scope="props">
                <font-awesome-icon
                  icon="check-circle"
                  class="icon-success"
                  v-if="props.option.value == 'Positive'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="times-circle"
                  class="icon-danger"
                  v-if="props.option.value == 'Negative'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="info-circle"
                  class="icon-info"
                  v-if="props.option.value == 'Neutral'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-1'"
                  v-if="props.option.value == 'Very low'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-3'"
                  v-if="props.option.value == 'Low'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-9'"
                  v-if="props.option.value == 'High'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-16'"
                  v-if="props.option.value == 'Very high'"/>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <font-awesome-icon
                  icon="check-circle"
                  class="icon-success"
                  v-if="props.option.value == 'Positive'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="times-circle"
                  class="icon-danger"
                  v-if="props.option.value == 'Negative'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="info-circle"
                  class="icon-info"
                  v-if="props.option.value == 'Neutral'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-1'"
                  v-if="props.option.value == 'Very low'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-3'"
                  v-if="props.option.value == 'Low'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-9'"
                  v-if="props.option.value == 'High'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-16'"
                  v-if="props.option.value == 'Very high'"/>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
                <span v-if="props.option.$groupLabel">
                  {{ props.option.$groupLabel }}
                </span>
              </template>
            </multiselect>

            <span class="pt-2">{{ option.title }}</span>
          </p>

          <div class="input-group pt-2">
            <b-form-input
                    qid="create-p-form-link"
                    type="text"
                    v-if="showInput"
                    v-on:keydown.enter="handleOption"
                    v-model="inputOption"
                    :placeholder="$t('maintenance.questions.create.options_placeholder')"
            ></b-form-input>
            <div class="input-group-append" v-if="showInput">
              <span class="input-group-text cursor-pointer"
                    id="inputGroup"
                    @click="handleOption"
                    qid="handle-link-save"
              >
                {{ $t('system.save') }}
              </span>
            </div>
          </div>

          <span class="btn btn-link cursor-pointer float-right"
                @click="showInput = true"
                v-if="!showInput"
                qid="create-p-add-link"
          >
            <font-awesome-icon icon="plus"/> {{ $t('maintenance.questions.create.add_option_label') }}
          </span>
        </b-form-group>

        <b-form-group v-if="responseType && responseType.value == 'Yes/No' && addResponse && addResponse.value && addResponse.value != 'No'">
          <label qid="create-p-link-label">
            {{ $t('maintenance.questions.create.options_label') }}
            <span class="text-red">*</span>
          </label>

          <p class="mt-1 mb-1 pb-3 pt-3">
            <multiselect
                    v-model="yesValue"
                    :options="answerTypes"
                    group-values="items"
                    group-label="group"
                    :group-select="false"
                    :placeholder="$t('maintenance.questions.create.type_placeholder')"
                    label="name"
                    :select-label="''"
                    :selected-label="''"
                    :deselect-label="''"
                    class="float-right mr-2"
                    style="width:220px;"
                    :allow-empty="false"
                    qid="create-p-form-pg"
            >
              <template slot="singleLabel" slot-scope="props">
                <font-awesome-icon
                  icon="check-circle"
                  class="icon-success"
                  v-if="props.option.value == 'Positive'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="times-circle"
                  class="icon-danger"
                  v-if="props.option.value == 'Negative'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="info-circle"
                  class="icon-info"
                  v-if="props.option.value == 'Neutral'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-1'"
                  v-if="props.option.value == 'Very low'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-3'"
                  v-if="props.option.value == 'Low'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-9'"
                  v-if="props.option.value == 'High'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-16'"
                  v-if="props.option.value == 'Very high'"/>

                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <font-awesome-icon
                  icon="check-circle"
                  class="icon-success"
                  v-if="props.option.value == 'Positive'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="times-circle"
                  class="icon-danger"
                  v-if="props.option.value == 'Negative'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="info-circle"
                  class="icon-info"
                  v-if="props.option.value == 'Neutral'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-1'"
                  v-if="props.option.value == 'Very low'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-3'"
                  v-if="props.option.value == 'Low'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-9'"
                  v-if="props.option.value == 'High'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-16'"
                  v-if="props.option.value == 'Very high'"/>

                <span class="ml-2">
                  {{ props.option.name }}
                </span>

                <span v-if="props.option.$groupLabel">
                  {{ props.option.$groupLabel }}
                </span>
              </template>
            </multiselect>

            <span class="mt-2">Yes</span>
          </p>

          <p class="mt-1 mb-1 pb-3 pt-3">
            <multiselect
                    v-model="noValue"
                    :options="answerTypes"
                    group-values="items"
                    group-label="group"
                    :group-select="false"
                    :placeholder="$t('maintenance.questions.create.type_placeholder')"
                    label="name"
                    :select-label="''"
                    :selected-label="''"
                    :deselect-label="''"
                    class="float-right mr-2"
                    style="width:220px;"
                    :allow-empty="false"
                    qid="create-p-form-pg"
            >
              <template slot="singleLabel" slot-scope="props">
                <font-awesome-icon
                  icon="check-circle"
                  class="icon-success"
                  v-if="props.option.value == 'Positive'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="times-circle"
                  class="icon-danger"
                  v-if="props.option.value == 'Negative'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="info-circle"
                  class="icon-info"
                  v-if="props.option.value == 'Neutral'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-1'"
                  v-if="props.option.value == 'Very low'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-3'"
                  v-if="props.option.value == 'Low'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-9'"
                  v-if="props.option.value == 'High'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-16'"
                  v-if="props.option.value == 'Very high'"/>

                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <font-awesome-icon
                  icon="check-circle"
                  class="icon-success"
                  v-if="props.option.value == 'Positive'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="times-circle"
                  class="icon-danger"
                  v-if="props.option.value == 'Negative'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="info-circle"
                  class="icon-info"
                  v-if="props.option.value == 'Neutral'"
                  style="background:white;border-radius:50%;"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-1'"
                  v-if="props.option.value == 'Very low'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-3'"
                  v-if="props.option.value == 'Low'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-9'"
                  v-if="props.option.value == 'High'"/>
                <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-16'"
                  v-if="props.option.value == 'Very high'"/>

                <span class="ml-2">
                  {{ props.option.name }}
                </span>

                <span v-if="props.option.$groupLabel">
                  {{ props.option.$groupLabel }}
                </span>
              </template>
            </multiselect>

            <span class="mt-2">No</span>
          </p>
        </b-form-group>

        <div class="text-muted mt-2 mb-3" v-if="responseType && responseType.value == 'Dropdown' && addResponse && addResponse.value && addResponse.value != 'No'">
          * {{ $t('maintenance.questions.create.helper_text_label') }}
        </div>
        <div class="text-muted mt-2 mb-3" v-if="responseType && responseType.value != 'Dropdown' && addResponse && addResponse.value && addResponse.value != 'No'">
          * {{ $t('maintenance.questions.create.yes_no_helper_text_label') }}
        </div>

        <b-form-group>
          <label qid="create-q-pg-label">
            {{ $t('maintenance.questions.create.add_notes_label') }}
            <span class="text-red">*</span>
          </label>
          <multiselect
                  v-model="addNotes"
                  :options="allowTypes"
                  :placeholder="$t('maintenance.questions.create.add_notes_placeholder')"
                  label="name"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="create-q-form-pg"
          ></multiselect>
        </b-form-group>
        <b-form-group>
          <label qid="create-q-pg-label">
            {{ $t('maintenance.questions.create.add_control_description_label') }}
            <span class="text-red">*</span>
          </label>
          <multiselect
                  v-model="addControlDescription"
                  :options="allowTypes"
                  :placeholder="$t('maintenance.questions.create.add_control_description_placeholder')"
                  label="name"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="create-q-form-pg"
          ></multiselect>
        </b-form-group>
        <b-form-group>
          <label qid="create-q-pg-label">
            {{ $t('maintenance.questions.create.add_performed_text_label') }}
            <span class="text-red">*</span>
          </label>
          <multiselect
                  v-model="addPerformedText"
                  :options="allowTypes"
                  :placeholder="$t('maintenance.questions.create.add_performed_text_placeholder')"
                  label="name"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="create-q-form-pg"
          ></multiselect>
        </b-form-group>
        <b-form-group>
          <label qid="create-q-pg-label">
            {{ $t('maintenance.questions.create.add_description_label') }}
            <span class="text-red">*</span>
          </label>
          <multiselect
                  v-model="addDescription"
                  :options="allowTypes"
                  :placeholder="$t('maintenance.questions.create.add_description_placeholder')"
                  label="name"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="create-q-form-pg"
          ></multiselect>
        </b-form-group>
        <b-form-group>
          <label qid="create-q-pg-label">
            {{ $t('maintenance.questions.create.add_attachments_label') }}
            <span class="text-red">*</span>
          </label>
          <multiselect
                  v-model="addAttachments"
                  :options="allowTypes"
                  :placeholder="$t('maintenance.questions.create.add_attachments_placeholder')"
                  label="name"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  qid="create-q-form-pg"
          ></multiselect>
        </b-form-group>
      </div>
      <div class="proxy-footer">
        <div class="progress-indicator-footer col text-center pt-2 pb-1">
          <ul class="progress-indicator pr-5 pl-5 mb-1">
            <li class="progress-completed">1</li>
            <li class="progress-completed">2</li>
            <li class="progress-completed">3</li>
            <li class="progress-active">4</li>
            <li class="progress-uncompleted">5</li>
          </ul>

          <ul class="progress-indicator-text pr-4 pl-4">
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.naming') }}</li>
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.framework_controls') }}</li>
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.documentation_collection_task') }}</li>
            <li class="text-center progress-completed">{{ $t('maintenance.questions.create.assessment_task') }}</li>
            <li class="text-center progress-uncompleted">{{ $t('maintenance.questions.create.complete') }}</li>
          </ul>
        </div>
        <div class="alert alert-menu-color color-secondary mb-0" role="alert">
          <div class="row">
            <div class="col-sm-6">

            </div>
            <div class="col-sm-6">
              <div class="text-right">
                <b-button class=""
                          variant="secondary"
                          @click="showCreateQForm3('back')"
                          qid="create-q-data_collection-back-button"
                >
                  {{ $t('maintenance.questions.create.back') }}
                </b-button>
                <b-button @click="showCreateQForm5"
                          class="ml-3"
                          variant="success"
                          :disabled="buttonDisabled"
                          qid="create-q-data_collection-submit-button"
                >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                  {{ $t('maintenance.questions.create.next') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>


    <b-modal ref="add-question-5"
             @hide="hideCreateQModal"
             modal-class="task-modal"
             hide-footer>

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideCreateQModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="create-q-title">
            {{ $t('maintenance.questions.create.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-q-error"
      >
        {{ error }}
      </b-alert>

      <b-form @submit="formSubmit" qid="create-q-form">
        <div class="form-container">
          <div class="mt-3 mb-3 text-center">
            <h5>{{ $t('maintenance.questions.create.submit_success_message') }}</h5>
          </div>
        </div>
        <div class="proxy-footer">
          <div class="progress-indicator-footer col text-center pt-2 pb-1">
            <ul class="progress-indicator pr-5 pl-5 mb-1">
              <li class="progress-completed">1</li>
              <li class="progress-completed">2</li>
              <li class="progress-completed">3</li>
              <li class="progress-completed">4</li>
              <li class="progress-active">5</li>
            </ul>

            <ul class="progress-indicator-text pr-4 pl-4">
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.naming') }}</li>
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.framework_controls') }}</li>
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.documentation_collection_task') }}</li>
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.assessment_task') }}</li>
              <li class="text-center progress-completed">{{ $t('maintenance.questions.create.complete') }}</li>
            </ul>
          </div>
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="showCreateQForm4"
                            qid="create-q-back-button"
                  >
                    {{ $t('maintenance.questions.create.back') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="create-q-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('maintenance.questions.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- Add new framework control -->
    <b-modal ref="add-framework-control" hide-footer @hide="hideAddFrameworkControlModal">
      <div class="d-block text-center">
        <h4 qid="add-io-title">
          {{ $t('generic_controls.framework_controls.create.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-io-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="frameworkControlFormSubmit" qid="create-framework-control-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-framewor-control-pg-label">
              {{ $t('generic_controls.framework_controls.create.framework_label') }}
            </label>
            <multiselect
                    v-model="selectedFramework"
                    :options="frameworkItems"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    :placeholder="$t('generic_controls.framework_controls.create.framework_placeholder')"
                    label="name"
                    @search-change="loadFrameworkItems"
                    @select="handleFrameworkChange"
                    track-by="slug"
                    qid="create-lb-form-pg"
            ></multiselect>
          </b-form-group>
          <b-form-group v-if="selectedFramework && selectedFramework.id">
            <label qid="create-framewor-control-pg-label">
              {{ $t('generic_controls.framework_controls.create.framework_control_label') }}
            </label>
            <multiselect
                    v-model="selectedFrameworkControl"
                    :options="frameworkControlItems"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    :placeholder="$t('generic_controls.framework_controls.create.framework_control_placeholder')"
                    label="full_name"
                    @search-change="loadFrameworkControlItems"
                    track-by="id"
                    qid="create-lb-form-pg"
            ></multiselect>
          </b-form-group>
          <div class="text-muted mt-2 mb-3">
            {{ $t('generic_controls.framework_controls.create.framework_control_tip') }}
          </div>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideAddFrameworkControlModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('generic_controls.framework_controls.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('generic_controls.framework_controls.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>


    <!-- Add new control objective -->
    <b-modal ref="add-control-objective"
             @hide="hideCreateControlObjectiveModal"
             modal-class="task-modal"
             hide-footer>

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideCreateControlObjectiveModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="add-co-title">
            {{ $t('maintenance.control_objective.create.title') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="add-co-error"
      >
        {{ error }}
      </b-alert>

      <b-form @submit="coFormSubmit" qid="create-co-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-control-objective-name-label">
              {{ $t('maintenance.control_objective.create.name_label') }}<span class="mandatory"> *</span>
            </label>
            <b-form-input
                    qid="create-control-objective-form-name"
                    type="text"
                    v-model="coForm.name"
                    :maxLength="255"
                    required
                    :placeholder="$t('maintenance.control_objective.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-control-objective-description-label">
              {{ $t('maintenance.control_objective.create.description_label') }}
            </label>
            <b-form-textarea
                    qid="create-control-objective-form-description"
                    v-model="coForm.description"
                    rows="6"
                    max-rows="6"
                    :placeholder="$t('maintenance.control_objective.create.description_placeholder')"
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideCreateControlObjectiveModal"
                            qid="create-control-objective-cancel-button"
                  >
                    {{ $t('maintenance.control_objective.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="create-control-objective-submit-button"
                  >
                        <span v-if="buttonDisabled" class="mr-1">
                          <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                        </span>
                    {{ $t('maintenance.control_objective.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>

    <TaskViewModal
        :selectedTask="selectedTask"
        :showModal="viewTaskModal"
        @closeViewModal="hideViewTaskModal"
    ></TaskViewModal>

    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>

    <ProjectViewModal
        :slug="selectedProjectSlug"
        :showModal="viewProjectModal"
        @closeViewModal="closeReminderModal"
    ></ProjectViewModal>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js';
  import Avatar from '@/components/Avatar.vue';
  // import Datepicker from 'vuejs-datepicker';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  import AssessmentSingleTaskModal from '@/views/Tasks/AssessmentSingleTaskModal.vue';
  import AssessmentGlobalSingleTaskModal from '@/views/Tasks/AssessmentGlobalSingleTaskModal.vue';
  import AssessmentGlobalMeasureSingleTaskModal from '@/views/Tasks/AssessmentGlobalMeasureSingleTaskModal.vue';
  import RiskSingleTaskModal from '@/views/Tasks/RiskSingleTaskModal.vue';
  import UpdateSingleTaskModal from '@/views/Tasks/UpdateSingleTaskModal.vue';
  import ValidationSingleTaskModal from '@/views/Tasks/ValidationSingleTaskModal.vue';
  import TaskViewModal from '@/views/Tasks/TaskViewModal.vue';
  import AssessmentTaskSideFilter from '@/components/tasks/AssessmentTaskSideFilter.vue';
  import AssessmentTaskTopBar from '@/components/tasks/AssessmentTaskTopBar.vue';
  import ProjectViewModal from '@/views/Projects/ProjectViewModal.vue';
  import UserInviteModal from '@/components/user/UserInviteModal.vue';

  export default {
    name: 'TasksTable',
    data: function() {
      return {
        sForm: {
          items: []
        },
        form: {
          due_date: '',
          start_date: '',
          message: ''
        },
        eform: {
          due_date: '',
          start_date: '',
          message: ''
        },
        keywordValue: '',
        filters: {
          category: '',
          keywordValue: ''
        },
        activity: {},
        sTableItems: [],
        selectedS: [],
        sLoading: true,
        error: '',
        itemToDelete: {},
        itemObject: {},
        buttonDisabled: false,
        open_tasks: {},
        loadingTasks: false,
        closed_tasks: {},
        showStatusForm: false,
        selectedStatus: [],

        //Default assignees
        userList: [],
        selectedDefaultTaskUsers: [],
        selectedDefaultDataCollectionUsers: [],
        applyOnTasks: 0,
        applyOnDataCollectionTasks: 0,
        defaultAssigneesForm: {
          scope_users: [],
          applyOnTasks: 0,
          data_collection_scope_users: [],
          applyOnDataCollectionTasks: 0,
        },

        // Project progress modal
        selectedProjectSlug: '',
        viewProjectModal: false,

        SideFilterRenderComponent: true,
        selectedCategory: {},

        options: [
          { name: this.$t('projects.status.pending'), value: 'pending' },
          { name: this.$t('projects.status.in_progress'), value: 'in progress' },
          { name: this.$t('projects.status.completed'), value: 'completed' },
          { name: this.$t('projects.status.closed'), value: 'closed' }
        ],
        selectedTask: [],
        selectedAssignUsers: [],
        selectedDataAssignUsers: [],
        selectedItem: {},
        showUsersModal: false,
        viewTaskModal: false,
        allUsers: [],

        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],

        questions: [],
        selectedQuestion: [],

        // Tasks pagination
        tasksPerPage: '',
        tasksCurrentPage: 1,
        tasksSelectedPerPage: "10",
        tasksTotalRows: 0,
        updateChildList: false,
        editUserType: '',
        loadFirstTime: true,
        selectedControlObjective: [],
        assessmentControlOpen: false,
        controlObjectives: [],
        coForm: {
          name: '',
          description: ''
        },
        files: [],
        files_two: [],
        data_collection_files: [],
        data_collection_on: 0,
        responseType: [],
        addControlDescription: [],
        addNotes: [],
        addPerformedText: [],
        addDescription: [],
        addResponse: [],
        addAttachments: [],
        dataCollectionAddDescription: [],
        dataCollectionAddAttachments: [],
        showLinksSection: false,
        showQuestionLinksSection: false,
        showQuestionTwoLinksSection: false,
        referenceInvalid: false,
        selectedFrameworkControls: [],
        selectedFramework: [],
        selectedFrameworkControl: [],
        frameworkControlItems: [],
        frameworkItems: [],
        assessment: {},
        dataCollectionOptions: [
          {text: this.$t('system.yes'), value: 1},
          {text: this.$t('system.no'), value: 0},
        ],
        showDropActive: false,
        responseTypes: [
          {
            group: this.$t('maintenance.questions.answer_type.input_label'),
            items: [
              { name: this.$t('maintenance.questions.type.datepicker'), value: 'Datepicker' },
            ]
          },
          {
            group: this.$t('maintenance.questions.answer_type.dropdown_label'),
            items: [
              { name: this.$t('maintenance.questions.type.dropdown'), value: 'Dropdown'},
              { name: this.$t('maintenance.questions.type.yes/no'), value: 'Yes/No'}
            ]
          }
        ],
        allowTypes: [
          { name: this.$t('maintenance.questions.type.no'), value: 'No' },
          { name: this.$t('maintenance.questions.type.optional'), value: 'Optional' },
          { name: this.$t('maintenance.questions.type.mandatory'), value: 'Mandatory' },
        ],
        allowTypesShort: [
          { name: this.$t('maintenance.questions.type.optional'), value: 'Optional' },
          { name: this.$t('maintenance.questions.type.mandatory'), value: 'Mandatory' },
        ],
        answerTypes: [
          {
            group: this.$t('maintenance.questions.answer_type.positive_negative_label'),
            items: [
              { name: this.$t('maintenance.questions.answer_type.neutral'), value: 'Neutral' },
              { name: this.$t('maintenance.questions.answer_type.positive'), value: 'Positive' },
              { name: this.$t('maintenance.questions.answer_type.negative'), value: 'Negative' },
            ]
          },
          {
            group: this.$t('maintenance.questions.answer_type.impact_assessment_label'),
            items: [
              { name: this.$t('maintenance.questions.answer_type.very_low'), value: 'Very low' },
              { name: this.$t('maintenance.questions.answer_type.low'), value: 'Low' },
              { name: this.$t('maintenance.questions.answer_type.high'), value: 'High' },
              { name: this.$t('maintenance.questions.answer_type.very_high'), value: 'Very high' },
            ]
          },
        ],
        yesNoOptions: [
          'Yes',
          'No'
        ],
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      Avatar,
      // Datepicker,
      ShowMoreModal,
      RiskSingleTaskModal,
      AssessmentSingleTaskModal,
      AssessmentGlobalSingleTaskModal,
      AssessmentGlobalMeasureSingleTaskModal,
      TaskViewModal,
      UpdateSingleTaskModal,
      AssessmentTaskTopBar,
      AssessmentTaskSideFilter,
      ValidationSingleTaskModal,
      ProjectViewModal,
      UserInviteModal
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getFiles() {
        return this.selectedFiles
      },
      getUser() {
        return this.$store.state.user
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getTasksPerPage() {
        let page = this.tasksCurrentPage;
        let selectedFrom = (page-1) * Number(this.tasksSelectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.tasksSelectedPerPage) + Number(this.tasksSelectedPerPage);
        let selectedOf = this.tasksTotalRows;

        if (selectedTo > selectedOf) {
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      },
      tasksRows() {
        // Use computed value for rows because of dynamic update
        return this.tasksTotalRows
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
      },
      object: function (value) {
        this.activity = value
        this.assessment = value.assessment
      }
    },
    methods: {
      lockSwitch(lockStatus) {
        let self = this;
        if (lockStatus) {
          this.data_collection_on = 0;
        }else{
          this.data_collection_on = 1;
        }
      },
      showEditCOModal(object) {
        this.coForm.slug = object.slug;
        this.coForm.id = object.id;
        this.coForm.name = object.name;
        this.coForm.description = object.description;
        this.$refs['edit-control-objective'].show()
      },
      hideEditCOModal() {
        this.$refs['edit-control-objective'].hide()
      },
      editCOFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        this.coForm.client = this.getClient.slug;
        this.coForm.assessment_id = this.assessment.id;

        if (this.coForm.name && this.coForm.name.length) {
          piincHttp.put('assessment-control-objectives/' + this.coForm.id, this.coForm).then(function(response) {
            self.hideEditCOModal();
            self.loadQuestions();
            self.$emit('updated');
            self.buttonDisabled = false;

            self.$toastr('success', self.$t('maintenance.control_objective.update.success'))
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.buttonDisabled = false;
          this.error = ''
          if (this.coForm.name == '') {
            this.error +=  ' ' + this.$t('maintenance.incidents.incident_categories.create.name_error')
          }
        }
      },
      resetFile() {
        let imagefile = document.querySelector('#file-rr');
        imagefile.value = '';
      },
      resetFileEdit() {
        let imagefile = document.querySelector('#file');
        imagefile.value = '';
      },
      onFileChange() {
        let files = document.querySelector('#file-rr');
        // in order for v-for to re render, we need to set array to empty
        this.selectedFiles = [];
        // And then push item by item into that array
        for (var i = 0; i < files.files.length; i++) {
          this.selectedFiles.push(files.files[i])
        }
      },
      handleLink() {
        // If there's a link in input, add it to links array
        if (this.inputLink !== '') {
          if (!this.inputLink.startsWith('http')) {
            this.inputLink = 'http://' + this.inputLink
          }
          this.form.links.push({url: this.inputLink, name: this.inputLinkName})
          // Reset link input form
          this.inputLink = ''
          this.inputLinkName = ''
        }
      },
      handleDataCollectionLink() {
        // If there's a link in input, add it to links array
        if (this.inputLinkDataCollection !== '') {
          if (!this.inputLinkDataCollection.startsWith('http')) {
            this.inputLinkDataCollection = 'http://' + this.inputLinkDataCollection
          }
          this.form.data_collection_links.push({url: this.inputLinkDataCollection, name: this.inputLinkNameDataCollection})
          // Reset link input form
          this.inputLinkDataCollection = ''
          this.inputLinkNameDataCollection = ''
        }
      },
      removeLinkItem(index) {
        // Remove link item from the links array
        this.form.links.splice(index, 1);
      },
      removeDataLinkItem(index) {
        // Remove link item from the links array
        this.form.data_collection_links.splice(index, 1);
      },
      removeMediaItem(index) {
        // Remove link item from the links array
        this.files.splice(index, 1);
      },
      removeDataMediaItem(index) {
        // Remove link item from the links array
        this.data_collection_files.splice(index, 1);
      },
      removeFrameworkControlItem(index) {
        // Remove link item from the links array
        this.selectedFrameworkControls.splice(index, 1);
      },
      showCreateControlObjectiveModal() {
        this.coForm.name = '';
        this.coForm.description = '';
        this.error = ''
        this.$refs['add-control-objective'].show()
      },
      hideCreateControlObjectiveModal() {
        this.coForm.name = '';
        this.coForm.description = '';
        this.error = ''
        this.loadCO()
        this.$refs['add-control-objective'].hide()
      },
      coFormSubmit(evt) {
        this.buttonDisabled = true;
        // let formData = new FormData();

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        this.coForm.client = this.getClient.slug;
        this.coForm.assessment_id = this.assessment.id;

        piincHttp.post('/assessment-control-objectives', this.coForm).then(function(response) {
          self.hideCreateControlObjectiveModal();
          self.loadQuestions();

          self.selectedControlObjective = response.data

          self.$emit('updated');
          self.buttonDisabled = false;

          self.$toastr('success', self.$t('maintenance.control_objective.create.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      showDropActiveFunction(value) {
        this.showDropActive = value;
      },
      handleType(value) {
        this.positiveAnswers = []
        this.negativeAnswers = []
        if (value && value.value == 'Dropdown' || value.value == 'System') {
          this.allAnswers = this.selectedOptions
          this.allAnswersCopy = _.cloneDeep(this.allAnswers);
        } else {
          this.noValue = this.answerTypes[0].items[0]
          this.yesValue = this.answerTypes[0].items[0]
          this.allAnswers = this.yesNoOptions
          this.allAnswersCopy = _.cloneDeep(this.allAnswers);
        }
      },
      loadCO(query) {
        let self = this;
        piincHttp.get('/assessment-control-objectives', { params:
            {
              client: this.getClient.slug,
              assessment: this.assessment.id,
              per_page: 100,
              keyword: query ? query : undefined
            }
        }).then(function(response) {
          self.controlObjectives = response.data.items

          if (self.controlObjectives && !self.controlObjectives.length && !query) {
            self.controlObjectives.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      debounceInput: _.debounce(function() {
        this.loadTasks()
      }, 400),
      reloadTasks() {
        this.loadTasks(this.tasksCurrentPage)
      },
      tasksPageChanged(page) {
        this.loadTasks(page)
      },
      getIcon(object) {
        let foundObject = {}
        let responseOptions = JSON.parse(object.response_type_options)

        foundObject = _.find(responseOptions, function(item) {
          return item.title === object.response;
        });

        if (foundObject) {
          return foundObject.value.value
        } else {
          return ''
        }

      },
      updateSelectedFilter(item) {
        if (!item) {
          this.filters.category = {}
          this.selectedFilterName = ''
        }else{
          this.filters.category = item
          this.selectedFilterName = item.name
        }

        this.selectedCategory = this.filters.category;
        this.loadingTasks = true;
        this.open_tasks = []
        this.debounceInput()
      },
      navigateToContent(object, type) {
        if (type == 'model') {
          if (object.model_type == 'App\\Activity\\Models\\Activity') {
            this.$router.push({ name: 'activities.view', params: { slug: object.model.slug }})
          }
          if (object.model_type == 'App\\Process\\Models\\Process') {
            this.$router.push({ name: 'processes.view', params: { slug: object.model.slug }})
          }
          if (object.model_type == 'App\\System\\Models\\System') {
            this.$router.push({ name: 'systems.view', params: { slug: object.model.slug, }})
          }
          if (object.model_type == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty') {
            this.$router.push({ name: 'contracts.view', params: { slug: object.model.slug, }})
          }
        } else {
          if (object.scope_type == 'App\\Activity\\Models\\Activity') {
            this.$router.push({ name: 'activities.view', params: { slug: object.scope.slug }})
          }
          if (object.scope_type == 'App\\Process\\Models\\Process') {
            this.$router.push({ name: 'processes.view', params: { slug: object.scope.slug }})
          }
          if (object.scope_type == 'App\\System\\Models\\System') {
            this.$router.push({ name: 'systems.view', params: { slug: object.scope.slug, }})
          }
          if (object.scope_type == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty') {
            this.$router.push({ name: 'contracts.view', params: { slug: object.scope.slug, }})
          }
        }
      },
      tasksPerPageChanged(perPage) {
        let self = this;
        self.tasksSelectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.tasksSelectedPerPage);
        // Load content when page changed listener is triggered
        this.loadTasks(1, perPage.value)
      },
      tasksPerPageRemoved() {
        let self = this;
        // Load content when page changed listener is triggered
        self.tasksSelectedPerPage = self.perPageOptions[0].value
        this.loadTasks(1, self.tasksSelectedPerPage)
      },
      showViewTaskModal(object) {
        this.selectedTask = object
        this.viewTaskModal = true
      },
      hideViewTaskModal() {
        this.selectedTask = {}
        this.viewTaskModal = false
      },
      showConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.updateChildList = false;
        this.$refs['delete-task'].show()
      },
      hideConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-task'].hide()
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      resetFields() {
        this.selectedRC = null
      },
      cancelForm() {
        this.showStatusForm = false
      },
      hideAddTaskModal() {
        this.selectedQuestion = [];
        this.$refs['add-task'].hide();
      },
      showAddTaskModal() {
        this.error = '';
        this.loadQuestions();
        this.updateChildList = false;
        this.$refs['add-task'].show();
      },
      showReminderModal(object) {
        this.selectedProjectSlug = object.slug;
        this.viewProjectModal = true;
      },
      closeReminderModal(statusChanged) {
        this.selectedProjectSlug = '';
        this.viewProjectModal = false;

        if (statusChanged) {
          this.loadProjects();
          this.loadTasks();
        }
      },
      showDefaultAssigneesModal(object) {
        this.loadUsers();
        this.selectedDefaultTaskUsers = object.scope_users;
        this.selectedDefaultDataCollectionUsers = object.data_collection_scope_users;

        this.defaultAssigneesForm.scope_users = [];
        this.defaultAssigneesForm.applyOnTasks = 0;
        this.defaultAssigneesForm.data_collection_scope_users = [];
        this.defaultAssigneesForm.applyOnDataCollectionTasks = 0;

        this.error = '';
        this.$refs['update-default-assignees'].show();
      },
      hideDefaultAssigneesModal() {
        this.error = '';
        this.$refs['update-default-assignees'].hide();
      },
      updateDefaultAssigneesFormSubmit(evt) {
        evt.preventDefault();
        this.updateDefaultTaskAssigneesFormSubmit();
        this.updateDefaultDataCollectionAssigneesFormSubmit();
        this.hideDefaultAssigneesModal();

      },
      updateDefaultTaskAssigneesFormSubmit(evt) {
        let self = this;

        this.defaultAssigneesForm.scope_users = [];
        if (this.selectedDefaultTaskUsers && this.selectedDefaultTaskUsers.length) {
          for (var i = 0; i < this.selectedDefaultTaskUsers.length; i++) {
            this.defaultAssigneesForm.scope_users.push(this.selectedDefaultTaskUsers[i].id);
          }
        }

        this.defaultAssigneesForm.apply_on_tasks = this.applyOnTasks

        piincHttp.put('projects/' + this.activity.slug, {
          scope_users: this.defaultAssigneesForm.scope_users,
          apply_on_tasks: this.applyOnTasks
        }).then(function(response) {
          self.project = response.data
          self.applyOnTasks = 0;
          self.loadTasks()
          self.$toastr('success', self.$t('projects.update.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.error = error.data.message;
          }
        })
      },
      updateDefaultDataCollectionAssigneesFormSubmit(evt) {
        let self = this;

        this.defaultAssigneesForm.data_collection_scope_users = [];
        if (this.selectedDefaultDataCollectionUsers && this.selectedDefaultDataCollectionUsers.length) {
          for (var i = 0; i < this.selectedDefaultDataCollectionUsers.length; i++) {
            this.defaultAssigneesForm.data_collection_scope_users.push(this.selectedDefaultDataCollectionUsers[i].id);
          }
        }

        this.defaultAssigneesForm.apply_on_data_collection_tasks = this.applyOnDataCollectionTasks

        piincHttp.put('projects/' + this.activity.slug, {
          data_collection_scope_users: this.defaultAssigneesForm.data_collection_scope_users,
          apply_on_tasks: this.applyOnDataCollectionTasks
        }).then(function(response) {
          self.project = response.data
          self.applyOnDataCollectionTasks = 0;
          // self.$emit('updated');
          self.loadTasks()
          self.$toastr('success', self.$t('projects.update.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.error = error.data.message;
          }
        })
      },
      addTaskFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let userIds = [];
        evt.preventDefault();

        if (this.selectedQuestion && this.selectedQuestion.id) {
          piincHttp.post('projects/' + this.object.slug + '/tasks', {
            question_id: this.selectedQuestion.id
          }).then(function(response) {
            self.hideAddTaskModal();
            self.updateChildList = true;
            self.loadTasks()
            self.$toastr('success', self.$t('tasks.add_task_success'))
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          self.error = self.$t('tasks.add_task.task_required')
          self.buttonDisabled = false;
        }
      },
      forceSideFilterRenderComponent(object) {
        // store selected category provided from the top bar component
        this.selectedCategory = object
      },
      hideEditUsersModal() {
        this.selectedTask = {}
        this.itemObject = {}
        this.editUserType = ''
        this.$refs['re-assign'].hide()
      },
      // Create modals
      showCreateQForm() {
        this.buttonDisabled = true;
        let self = this;
        this.error = '';
        this.selectedOptions = [];
        this.inputOption = '';
        this.positiveAnswers = [];
        this.negativeAnswers = [];
        this.selectedControlObjective = [];
        this.allAnswers = [];
        this.responseType = [];
        this.selectedFiles = [];
        this.selectedFrameworkControls = [];
        this.form.links = [];
        this.inputLink = '';
        this.inputLinkName = '';
        this.inputLinkDataCollection = '';
        this.inputLinkNameDataCollection = '';
        this.data_collection_on = 0;
        this.showInput = true;
        this.showOptionInput = true;
        this.referenceInvalid = false;

        this.form.name = '';
        this.form.description = '';
        this.form.description_two = '';
        this.form.data_collection_description = '';
        this.form.data_collection_description_two = '';
        this.form.reference = '';

        this.form.links = [];
        this.form.data_collection_links = [];

        this.files = [];
        this.files_two = [];
        this.data_collection_files = [];
        this.addDescription = this.allowTypes[1];
        this.addResponse = this.allowTypes[1];
        this.addControlDescription = this.allowTypes[1];
        this.addNotes = this.allowTypes[1];
        this.addPerformedText = this.allowTypes[1];
        this.addAttachments = this.allowTypes[1];
        this.dataCollectionAddDescription = this.allowTypes[1];
        this.dataCollectionAddAttachments = this.allowTypes[1];
        this.showLinksSection = false;
        this.showQuestionLinksSection = false;
        this.showQuestionTwoLinksSection = false;

        this.selectedQuestionDependency = [];
        this.selectedAnswers = [];

        this.loadCO();

        self.$refs['add-task'].hide();

        self.$refs['add-question-1'].show();
        self.$refs['add-question-2'].hide();
        self.$refs['add-question-3'].hide();
        self.$refs['add-question-4'].hide();
        self.$refs['add-question-5'].hide();
        self.buttonDisabled = false;
      },
      showCreateQForm1() {
        this.buttonDisabled = true;
        let self = this;
        self.$refs['add-question-1'].show()
        self.$refs['add-question-2'].hide()
        self.$refs['add-question-3'].hide()
        self.$refs['add-question-4'].hide()
        self.$refs['add-question-5'].hide()
        self.buttonDisabled = false;
      },
      showCreateQForm2(type) {
        this.buttonDisabled = true;
        let self = this;
        self.error =''

        if (type && type == 'forward') {
          if (self.form.name && self.form.name.length && this.selectedControlObjective && this.selectedControlObjective.id) {
            self.$refs['add-question-1'].hide()
            self.$refs['add-question-2'].show()
            self.$refs['add-question-3'].hide()
            self.$refs['add-question-4'].hide()
            self.$refs['add-question-5'].hide()
          } else {
            self.error = ''
            if (!self.form.name.length) {
              this.error += ' ' + this.$t('maintenance.data_objects.create.name_error')
            }
            if (!this.selectedControlObjective || (this.selectedControlObjective && !this.selectedControlObjective.id)) {
              this.error += ' ' + this.$t('maintenance.questions.create.control_objective_error_message')
            }

          }
        } else {
          self.$refs['add-question-1'].hide()
          self.$refs['add-question-2'].show()
          self.$refs['add-question-3'].hide()
          self.$refs['add-question-4'].hide()
          self.$refs['add-question-5'].hide()
        }

        self.buttonDisabled = false;
      },
      showCreateQForm3(type) {
        this.buttonDisabled = true;
        let self = this;
        self.error =''

        self.$refs['add-question-1'].hide()
        self.$refs['add-question-2'].hide()
        self.$refs['add-question-3'].show()
        self.$refs['add-question-4'].hide()
        self.$refs['add-question-5'].hide()

        self.buttonDisabled = false;
      },
      showCreateQForm4(type) {
        this.buttonDisabled = true;
        let self = this;
        let sizeError = false;
        self.error =''

        if (type && type == 'forward') {

          for (var i = 0; i < this.data_collection_files.length; i++) {
            // Equal to 10 MB
            if (this.data_collection_files[i].size > 31457280) {
              sizeError = true;
            }
          }

          if ((self.data_collection_on && self.form.data_collection_description.length && this.dataCollectionAddAttachments && this.dataCollectionAddDescription.value && this.dataCollectionAddAttachments && this.dataCollectionAddAttachments.value && !sizeError) || !self.data_collection_on) {
            self.$refs['add-question-1'].hide()
            self.$refs['add-question-2'].hide()
            self.$refs['add-question-3'].hide()
            self.$refs['add-question-4'].show()
            self.$refs['add-question-5'].hide()
          } else {
            self.error = ''
            if (!self.form.data_collection_description.length) {
              this.error += ' ' + this.$t('maintenance.questions.create.data_collection_description_error_message')
            }
            if (sizeError) {
              this.error += ' ' + this.$t('system.file_size_30')
            }
            if (!this.dataCollectionAddDescription || (this.dataCollectionAddDescription && !this.dataCollectionAddDescription.value)) {
              this.error += ' ' + this.$t('maintenance.questions.create.add_description_error_message')
            }
            if (!this.dataCollectionAddAttachments || (this.dataCollectionAddAttachments && !this.dataCollectionAddAttachments.value)) {
              this.error += ' ' + this.$t('maintenance.questions.create.add_attachments_error_message')
            }
          }
        } else {
          self.$refs['add-question-1'].hide()
          self.$refs['add-question-2'].hide()
          self.$refs['add-question-3'].hide()
          self.$refs['add-question-4'].show()
          self.$refs['add-question-5'].hide()
        }

        self.buttonDisabled = false;
      },
      showCreateQForm5() {
        this.buttonDisabled = true;
        let self = this;
        let sizeError = false;
        self.error =''

        for (var i = 0; i < this.files.length; i++) {
          // Equal to 10 MB
          if (this.files[i].size > 31457280) {
            sizeError = true;
          }
        }

        if (!sizeError && ((this.responseType && this.responseType.value) || (this.addResponse && this.addResponse.value == 'No')) && this.form.description.length && this.addDescription && this.addDescription.value && this.addResponse && this.addResponse.value && this.addControlDescription && this.addControlDescription.value && this.addNotes && this.addNotes.value && this.addPerformedText && this.addPerformedText.value && this.addAttachments && this.addAttachments.value && ((this.addResponse && this.addResponse.value == 'No') || (this.responseType && (this.responseType.value == 'Datepicker' || this.responseType.value == 'Yes/No')) || (this.responseType && this.responseType.value == 'Dropdown' && this.selectedOptions && this.selectedOptions.length))) {
          self.$refs['add-question-1'].hide()
          self.$refs['add-question-2'].hide()
          self.$refs['add-question-3'].hide()
          self.$refs['add-question-4'].hide()
          self.$refs['add-question-5'].show()
        } else {
          this.error = '';
          if (!self.form.description.length) {
            this.error += ' ' + this.$t('maintenance.questions.create.description_error_message')
          }
          if (sizeError) {
            this.error += ' ' + this.$t('system.file_size_30')
          }
          if (!this.responseType || (this.responseType && !this.responseType.value)) {
            this.error += ' ' + this.$t('maintenance.questions.create.response_type_error_message')
          }
          if (!this.addDescription || (this.addDescription && !this.addDescription.value)) {
            this.error += this.$t('maintenance.questions.create.add_description_error_message')
          }
          if (!this.addResponse || (this.addResponse && !this.addResponse.value)) {
            this.error += this.$t('maintenance.questions.create.add_response_error_message')
          }
          if (!this.addControlDescription || (this.addControlDescription && !this.addControlDescription.value)) {
            this.error += this.$t('maintenance.questions.create.add_control_description_error_message')
          }
          if (!this.addNotes || (this.addNotes && !this.addNotes.value)) {
            this.error += this.$t('maintenance.questions.create.add_notes_error_message')
          }
          if (!this.addPerformedText || (this.addPerformedText && !this.addPerformedText.value)) {
            this.error += this.$t('maintenance.questions.create.add_performed_text_error_message')
          }
          if (!this.addAttachments || (this.addAttachments && !this.addAttachments.value)) {
            this.error += this.$t('maintenance.questions.create.add_attachments_error_message')
          }
          if ((this.responseType && this.responseType.value == 'Select') && this.selectedOptions && !this.selectedOptions.length) {
            this.error += this.$t('maintenance.questions.create.selected_options_error_message')
          }
        }

        self.buttonDisabled = false;
      },
      reloadUserList() {
        this.loadUsers();
      },
      loadUsers(query) {
        let self = this;

        this.loading = true;
        this.buttonDisabled = true;

        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.userList = response.data.items
          self.buttonDisabled = false;
          self.loading = false;
        }, function() {});
      },
      showEditUsersModal(object) {
        let self = this;
        let role_name = [self.permissions.ROLE_CLIENT_ADMIN];

        role_name.push(self.permissions.ROLE_MEMBER);
        role_name.push(self.permissions.ROLE_VALIDATOR);

        if (this.getClient && this.getClient.parent) {
          role_name.push(self.permissions.ROLE_PARTITION_ADMIN);
        }

        this.error = '';
        this.itemObject = object;
        this.selectedAssignUsers = [];
        this.selectedDataAssignUsers = [];
        this.buttonDisabled = true;
        this.loading = true;

        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            role_name: role_name,
            per_page: 100
          }
        }).then(function(response) {
          let users = response.data.items

          _.forEach(object.users, function (user) {
            if (_.findIndex(users, function(o) { return o.id == user.id; }) == -1) {
              if (user.id != self.getUser.id) {
                users.push(user)
              }
            }

            let matchedUser = _.cloneDeep(user)
            if (user.id == object.owner) {
              matchedUser.owner = true
            }
            self.selectedAssignUsers.push(matchedUser)
          })
          _.forEach(object.data_users, function (user) {
            if (_.findIndex(users, function(o) { return o.id == user.id; }) == -1) {
              if (user.id != self.getUser.id) {
                users.push(user)
              }
            }

            let matchedUser = _.cloneDeep(user)
            if (user.id == object.owner) {
              matchedUser.owner = true
            }
            self.selectedDataAssignUsers.push(matchedUser)
          })

          self.allUsers = _.uniq(users)
          self.buttonDisabled = false;
          self.loading = false;
        }, function() {});

        this.selectedTask = object
        this.$refs['re-assign'].show()
      },
      reassignFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let userIds = [];
        let datauserIds = [];
        evt.preventDefault();

        if (this.selectedAssignUsers && this.selectedAssignUsers.length) {
          for (var i = 0; i < this.selectedAssignUsers.length; i++) {
            userIds.push(this.selectedAssignUsers[i].id);
          }
        }
        if (this.selectedDataAssignUsers && this.selectedDataAssignUsers.length) {
          for (var i = 0; i < this.selectedDataAssignUsers.length; i++) {
            datauserIds.push(this.selectedDataAssignUsers[i].id);
          }
        }

        // if (userIds && userIds.length && datauserIds && datauserIds.length) {
          piincHttp.put('tasks/' + this.selectedTask.id, {
            users: userIds,
            data_users: datauserIds,
          }).then(function(response) {
            self.hideEditUsersModal();
            self.loadTasks()
            self.$toastr('success', self.$t('tasks.re_assign.success'))
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        // } else {
        //   self.error = self.$t('tasks.re_assign.required')
        //   self.buttonDisabled = false;
        // }
      },
      showFormFunction() {
        let self = this
        this.selectedStatus = []
        if (this.selectedS.status) {
          this.selectedStatus = _.find(this.options, function(item) {
            return item.value === self.selectedS.status;
          });
        }
        this.showStatusForm = true
      },
      formStatusSubmit() {
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        this.form.processing_role = this.processingRole ? this.processingRole.value : '';

        piincHttp.put('projects/' + this.selectedS.slug, {
          status: this.selectedStatus.value
        }).then(function(response) {
          self.selectedS = response.data
          self.cancelForm();
          self.loadProjects();
          self.$toastr('success', self.$t('projects.update.success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      sendReminders(object) {
        let self = this;
        // Call API for reminders
        piincHttp.get('projects/' + object.slug + '/reminders').then(function() {
          self.$toastr('success', self.$t('projects.create.project_reminders_success'))
        }, function() {});
      },
      openTasksTabClicked() {
        this.loadTasks()
        this.showClosedTasksTab = false
        this.showOpenTasksTab = true
      },
      closedTasksTabClicked() {
        this.loadClosedTasks()
        this.showClosedTasksTab = true;
        this.showOpenTasksTab = false
      },
      loadTasks(page, perPage) {
        let self = this;
        this.loadingTasks = true;

        if (this.getStorePerPage) {
          this.tasksSelectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.tasksSelectedPerPage;

        if(self.activity.type == 'control_assessment'){
          // Call API for open tasks
          piincHttp.get('projects/' + this.object.slug + '/tasks', { params:
              {
                client: this.getClient.slug,
                page: pageNumber,
                not_code: 'update_measures',
                control_objective: this.selectedCategory && this.selectedCategory.id ? this.selectedCategory.id : undefined,
                per_page: perPageNumber,
              }
          }).then(function(response) {
            self.open_tasks = response.data.items;
            // Set number of total items
            self.tasksTotalRows = response.data.total_count;
            self.loadingTasks = false;
            self.loadFirstTime = false;
            self.$emit('updated');
          }, function() {});
        }else{

          // Call API for open tasks
          piincHttp.get('projects/' + this.object.slug + '/tasks', { params:
              {
                client: this.getClient.slug,
                page: pageNumber,
                not_code: 'update_measures',
                model_type: this.selectedCategory && this.selectedCategory.who_am_i ? this.selectedCategory.who_am_i : undefined,
                model_id: this.selectedCategory && this.selectedCategory.id ? this.selectedCategory.id : undefined,
                per_page: perPageNumber,
              }
          }).then(function(response) {
            self.open_tasks = response.data.items;
            // Set number of total items
            self.tasksTotalRows = response.data.total_count;
            self.loadingTasks = false;
            self.loadFirstTime = false;
            self.$emit('updated');
          }, function() {});
        }

      },
      loadClosedTasks() {
        let self = this;
        // Call API for closed tasks
        piincHttp.get('projects/' + this.object.slug + '/tasks', { params:
          {
            status: 'Completed',
            client: this.getClient.slug,
            per_page: 100
          }
        }).then(function(response) {
          self.closed_tasks = response.data.items;
        }, function() {});
      },
      downloadTasks() {
        let self = this;
        // Call API for open tasks
        piincHttp.get('projects/' + this.object.slug + '/tasks/download',{responseType: 'blob'}).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Project tasks.xlsx');

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function() {});


      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users && users.length > 3) {
          reducedUsers = _.cloneDeep(users);
          reducedUsers = reducedUsers.slice(0, 3);
        } else {
          reducedUsers = users;
        }
        return reducedUsers;
      },
      showEditModal(object) {
        let self = this;
        this.error = '';

        this.selectedS = object
        this.loadTasks()
        this.loadClosedTasks()
        this.eform.due_date = this.object.end_date;
        this.$refs['edit-item'].show()
      },
      hideEditModal() {
        this.selectedS = {}
        this.selectedUsers = [],
        this.$refs['edit-item'].hide()
      },
      editFormSubmit(evt) {
        var moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        this.eform.due_date = this.eform.due_date ? moment(this.eform.due_date).format('YYYY-MM-DD') : '';

        piincHttp.put('projects/' + this.selectedS.slug, {end_date: this.eform.due_date}).then(function(response) {
          self.hideEditModal();
          self.loadProjects();
          self.$toastr('success', self.$t('projects.update.success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      showEditSModal(object) {
        this.eform.due_date = object.end_date;
        this.$refs['edit-system'].show()
      },
      hideEditSModal() {
        this.$refs['edit-system'].hide()
      },
      confirmDeleteS(object) {
        let self = this;
        piincHttp.delete('tasks/' + object.id).then(function() {
          self.hideConfirmDeleteModal();
          self.loadTasks();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('tasks.delete.success'))
          self.$emit('updated');
        }, function() {});
      },
      showCreateModal() {
        let self = this;
        this.error = '';
        this.$refs['create-item'].show()
      },
      hideCreateModal() {
        this.selectedS = {}
        this.selectedUsers = []
        this.form.due_date = ''
        this.form.start_date = ''
        this.$refs['create-item'].hide()
      },
      createFormSubmit(evt) {
        var moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        let formData = new FormData();

        this.form.due_date = this.form.due_date ? moment(this.form.due_date).format('YYYY-MM-DD') : '';
        formData.append("due_date", this.form.due_date);

        this.form.start_date = this.form.start_date ? moment(this.form.start_date).format('YYYY-MM-DD') : '';
        formData.append("start_date", this.form.start_date);

        formData.append("message", this.form.message);

        piincHttp.post('projects/' + this.$route.params.slug + '/projects', formData, {

        }).then(function(response) {
          self.hideCreateModal();
          self.loadProjects();
          // Emit event to parent component to update newest validation
          self.$toastr('success', self.$t('projects.create.project_success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.start_date) {
              self.error += ' ' + error.data.errors.start_date[0]
            }
            if (error.data.errors.due_date) {
              self.error += ' ' + error.data.errors.due_date[0]
            }
          }
        })
      },
      loadQuestions() {
        let self = this;
        piincHttp.get('questions/no-tasks', { params:
          {
            client: this.getClient.slug,
            assessment: this.activity.assessment ? this.activity.assessment.id : undefined,
            project: this.activity.id,
            per_page: 100
          }
        }).then(function(response) {
          self.questions = response.data.items;
        }, function() {});
      },
      showAddFrameworkControlModal() {
        this.assessmentControlOpen = true
        this.selectedFramework = [];
        this.error = '';
        this.selectedFrameworkControl = [];
        this.loadFrameworkItems();
        this.$refs['add-framework-control'].show()
      },
      hideAddFrameworkControlModal() {
        this.assessmentControlOpen = false
        this.error = ''
        this.selectedFramework = [];
        this.selectedFrameworkControl = []
        this.$refs['add-framework-control'].hide()
      },
      handleFrameworkChange(item) {
        this.selectedFramework = item
        this.loadFrameworkControlItems()
      },
      loadFrameworkItems(query) {
        let self = this;
        piincHttp.get('frameworks', { params:
            {
              client: this.getClient.slug,
              per_page: 100,
              keyword: query ? query : undefined
            }
        }).then(function(response) {
          self.frameworkItems = response.data.items
          if (self.frameworkItems && !self.frameworkItems.length && !query) {
            self.frameworkItems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadFrameworkControlItems(query) {
        let self = this;
        piincHttp.get('framework-controls', { params:
            {
              client: this.getClient.slug,
              per_page: 100,
              framework: this.selectedFramework.id,
              keyword: query ? query : undefined
            }
        }).then(function(response) {
          self.frameworkControlItems = response.data.items
          if (self.frameworkControlItems && !self.frameworkControlItems.length && !query) {
            self.frameworkControlItems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      frameworkControlFormSubmit(evt) {
        let self = this
        evt.preventDefault();

        let addToArray = false

        if (self.selectedFrameworkControl && self.selectedFrameworkControl.id) {
          if (this.selectedFrameworkControls.length) {
            _.forEach(this.selectedFrameworkControls, function (item, index) {
              if (item.id != self.selectedFrameworkControl.id) {
                addToArray = true
              }
            })
          } else {
            addToArray = true
          }

          if (addToArray) {
            this.selectedFrameworkControls.push(this.selectedFrameworkControl)
          }

          this.hideAddFrameworkControlModal()
        } else {
          this.error = this.$t('maintenance.questions.create.framework_control_error')
        }

      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();

        let sizeError = false;

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        this.handleLink()
        this.handleDataCollectionLink()

        formData.append("assessment_id", this.assessment.id);
        formData.append("name", this.form.name);
        formData.append("reference", this.form.reference);
        formData.append("description", this.form.description);
        formData.append("description_two", this.form.description_two);

        // Data collection formData
        if (this.data_collection_on) {

          formData.append("data_collection_on", this.data_collection_on);
          formData.append("data_collection_description", this.form.data_collection_description);
          formData.append("data_collection_description_two", this.form.data_collection_description_two);

          if (this.dataCollectionAddDescription && this.dataCollectionAddDescription.value) {
            formData.append("data_collection_add_description", this.dataCollectionAddDescription.value);
          }

          if (this.dataCollectionAddAttachments && this.dataCollectionAddAttachments.value) {
            formData.append("data_collection_add_attachments", this.dataCollectionAddAttachments.value);
          }

          // Append list of links to FormData
          for (var i = 0; i < this.form.data_collection_links.length; i++) {
            formData.append("data_collection_links[]", JSON.stringify(this.form.data_collection_links[i]));
          }

          // Append list of attachments to FormData
          for (var i = 0; i < this.data_collection_files.length; i++) {
            // Equal to 10 MB
            if (this.data_collection_files[i].size > 31457280) {
              sizeError = true;
            }
            formData.append("data_collection_files[]", this.data_collection_files[i].file);
          }

          for (var i = 0; i < this.data_collection_two_files.length; i++) {
            // Equal to 10 MB
            if (this.data_collection_two_files[i].size > 31457280) {
              sizeError = true;
            }
            formData.append("data_collection_two_files[]", this.data_collection_two_files[i].file);
          }

        } else {
          formData.append("data_collection_on", 0);
        }

        // Append list of attachments to FormData
        for (var i = 0; i < this.files.length; i++) {
          // Equal to 10 MB
          if (this.files[i].size > 31457280) {
            sizeError = true;
          }
          formData.append("files[]", this.files[i].file);
        }

        // Append list of attachments to FormData
        for (var i = 0; i < this.files_two.length; i++) {
          // Equal to 10 MB
          if (this.files_two[i].size > 31457280) {
            sizeError = true;
          }
          formData.append("files_two[]", this.files_two[i].file);
        }


        // Append list of links to FormData
        for (var i = 0; i < this.form.links.length; i++) {
          formData.append("links[]", JSON.stringify(this.form.links[i]));
        }

        for (var i = 0; i < this.selectedFrameworkControls.length; i++) {
          formData.append("framework_controls[]", this.selectedFrameworkControls[i].id);
        }

        if (this.responseType && this.responseType.value) {
          formData.append("response_type", this.responseType.value);
        }

        if (this.selectedControlObjective && this.selectedControlObjective.id) {
          formData.append("control_objective_id", this.selectedControlObjective.id);
        }

        if (this.addDescription && this.addDescription.value) {
          formData.append("add_description", this.addDescription.value);
        }
        if (this.addResponse && this.addResponse.value) {
          formData.append("add_response", this.addResponse.value);
        }
        if (this.addControlDescription && this.addControlDescription.value) {
          formData.append("add_control_description", this.addControlDescription.value);
        }
        if (this.addNotes && this.addNotes.value) {
          formData.append("add_notes", this.addNotes.value);
        }
        if (this.addPerformedText && this.addPerformedText.value) {
          formData.append("add_performed_text", this.addPerformedText.value);
        }

        if (this.addAttachments && this.addAttachments.value) {
          formData.append("add_attachments", this.addAttachments.value);
        }

        if (this.responseType && this.responseType.value && this.responseType.value == 'Yes/No') {
          let array = [
            {title: 'Yes', value: this.yesValue},
            {title: 'No', value: this.noValue},
          ]
          formData.append("response_type_options", JSON.stringify(array))
        }

        if (this.responseType && this.responseType.value && this.responseType.value == 'Dropdown') {
          if (this.selectedOptions && this.selectedOptions.length) {
            formData.append("response_type_options", JSON.stringify(this.selectedOptions))
          }
        }

        if (this.selectedQuestionDependency && this.selectedQuestionDependency.id) {
          formData.append("dependency_question_id", this.selectedQuestionDependency.id);
          for (var i = 0; i < this.selectedAnswers.length; i++) {
            formData.append("dependency_question_answer[]", this.selectedAnswers[i].title);
          }
        } else {
          formData.append("dependency_question_id", '');
        }

        if (sizeError) {
          this.error = this.$t('system.file_size_30')
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;

          if (((this.responseType && this.responseType.value) || (this.addResponse && this.addResponse.value == 'No')) && this.form.description.length && this.addDescription && this.addDescription.value && this.addResponse && this.addResponse.value && this.addControlDescription && this.addControlDescription.value && this.addNotes && this.addNotes.value && this.addPerformedText && this.addPerformedText.value && this.addAttachments && this.addAttachments.value && ((this.addResponse && this.addResponse.value == 'No') || (this.responseType && (this.responseType.value == 'Datepicker' || this.responseType.value == 'Yes/No')) || (this.responseType && this.responseType.value == 'Dropdown' && this.selectedOptions && this.selectedOptions.length))) {

            piincHttp.post('projects/' + this.object.slug + '/task-create', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(function(response) {
              self.hideCreateQModal();
              self.updateChildList = true;
              self.loadTasks()
              self.$toastr('success', self.$t('tasks.add_task_success'))
              self.buttonDisabled = false;
            }, function(error) {
              if (error.status !== 200) {
                self.buttonDisabled = false;
                self.error = error.data.message;
              }
            })

            // piincHttp.post(' ', formData, {
            //   headers: {
            //     'Content-Type': 'multipart/form-data'
            //   }
            // }).then(function(response) {
            //   self.$toastr('success', self.$t('maintenance.questions.create.success'))
            //   self.hideCreateQModal();
            //   self.loadQuestions();
            //   self.$emit('updated');
            //   self.buttonDisabled = false;
            // }, function(error) {
            //   if (error.status !== 200) {
            //     self.buttonDisabled = false;
            //     self.error = error.data.message;
            //   }
            // })
          } else {
            this.error = '';
            self.buttonDisabled = false;
            if (!this.responseType || (this.responseType && !this.responseType.value)) {
              this.error += ' ' + this.$t('maintenance.questions.create.response_type_error_message')
            }
            if (!this.addDescription || (this.addDescription && !this.addDescription.value)) {
              this.error += this.$t('maintenance.questions.create.add_description_error_message')
            }
            if (!this.addResponse || (this.addResponse && !this.addResponse.value)) {
              this.error += this.$t('maintenance.questions.create.add_response_error_message')
            }
            if (!this.addAttachments || (this.addAttachments && !this.addAttachments.value)) {
              this.error += this.$t('maintenance.questions.create.add_attachments_error_message')
            }
            if ((this.responseType && this.responseType.value == 'Select') && this.selectedOptions && !this.selectedOptions.length) {
              this.error += this.$t('maintenance.questions.create.selected_options_error_message')
            }
          }
        }

      },
      hideCreateQModal() {
        this.$refs['add-question-1'].hide()
        this.$refs['add-question-2'].hide()
        this.$refs['add-question-3'].hide()
        this.$refs['add-question-4'].hide()
        this.$refs['add-question-5'].hide()
      },
    },
    created () {
      this.activity = this.object;
      if (this.getClient && this.getClient.slug) {

      }
    }
  }
</script>
