<template>
  <div>
      <div class="card" qid="basic-info-section">
        <div class="card-body">
          <div class="content-view-body">
            <div class="">
              <h5 class="h5 mb-3">
                <span class="float-right btn-sm btn-edit"
                      @click="showDescriptionInputFunction"
                      qid="activity-edit-description"
                      v-if="(!showDescriptionInput && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed') && ((activity.additional_client && getClient.slug != activity.additional_client.slug) || activity.additional_client == null)"
                >
                  <font-awesome-icon icon="pen"/>
                </span>

                <span class="float-right" v-if="showDescriptionInput">
                  <div class="text-right">
                    <b-button class="btn-sm inline-edit-button"
                              variant="secondary"
                              @click="cancelDescriptionForm"
                              qid="activity-edit-button-cancel"
                    >
                      {{ $t('activities.create.cancel') }}
                    </b-button>
                    <b-button class="ml-3 btn-sm inline-edit-button"
                              variant="success"
                              @click="formDescriptionSubmit"
                              qid="activity-edit-button-submit"
                    >
                      {{ $t('activities.create.submit') }}
                    </b-button>
                  </div>
                </span>
                <span class="mr-3 btn btn-success float-right cursor-pointer"
                      @click="showObservations()"
                      v-if="activity.control_type === 'one time' && (activity.type === 'control_assessment' || activity.type === 'risk_assessment')"
                      qid="reporting-generate_report-button"
                >
                  {{$t('projects.tab.observations')}}
                </span>

                {{ $t('table.summary') }}
              </h5>

              <div v-if="showDescriptionInput">
                <b-form-textarea
                  qid="edit-tp-form-description"
                  v-model="activityEditSummary"
                  rows="6"
                  :placeholder="$t('table.summary')"
                ></b-form-textarea>
              </div>
              <div v-if="!showDescriptionInput" class="section-container">
                <div v-if="activity.summary && activity.summary.length > descriptionLength">
                  <p v-if="showFullDescription" class="text-formatted">{{ activity.summary }}</p>
                  <p v-if="!showFullDescription" class="text-formatted">{{ activity.summary | truncate(descriptionLength) }}</p>
                  <div class="text-center mt-3" @click="showFullDescription=!showFullDescription">
                    <span class="cursor-pointer" v-if="!showFullDescription">
                      <span class="mr-2">
                        {{ $t('system.view_more') }}
                      </span>
                      <font-awesome-icon icon="chevron-down"/>
                    </span>
                    <span class="cursor-pointer" v-if="showFullDescription">
                      <span class="mr-2">
                        {{ $t('system.view_less') }}
                      </span>
                      <font-awesome-icon icon="chevron-up"/>
                    </span>
                  </div>
                </div>
                <p v-if="activity.summary && activity.summary.length <= descriptionLength"
                   class="text-formatted"
                >{{ activity.summary }}</p>
                <p v-if="!activity.summary" class="text-muted">
                  {{ $t('system.missing_input') }}
                </p>
              </div>
              <br>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="card" qid="basic-info-section">

        <div class="card-body">
          <div>
            <h5 class="mb-0">
              {{$t('maintenance.reports.generate_new')}}

              <span class="mr-3 btn btn-success float-right cursor-pointer"
                    @click="generateReport()"
                    v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed') && ((activity.additional_client && getClient.slug != activity.additional_client.slug) || activity.additional_client == null)"
                    qid="reporting-generate_report-button"
              >
                {{ $t('maintenance.reports.generate_new') }}
              </span>

              <DownloadAllAttachmentsModal
                  class="float-right"
                  :object="activity"
                  v-if="activity.type && (activity.type == 'control_assessment' || activity.type == 'risk_assessment')"
              ></DownloadAllAttachmentsModal>
            </h5>

            <b-alert v-if="reportError.length"
                     show
                     variant="danger"
                     class="mt-4"
                     qid="attachments-tp-error"
            >
              {{ reportError }}
            </b-alert>

            <div class="mt-3" v-if="activity.task_count != 0 && activity.task_count != undefined && activity.progress != 100 && activity.status != 'completed'">
              <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
              {{$t('tasks.messages.unresolved_tasks')}}
            </div>
            <div class="mt-2" v-if="measureTask && measureTask.id && measureTask.status != 'completed'">
              <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
              {{$t('tasks.messages.measure_unresolved_tasks')}}
            </div>
            <div class="mt-2" v-if="activity.system_risk_count != 0 && activity.system_risk_count != undefined">
              <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
              {{$t('tasks.messages.unresolved_risk_assessments')}}
            </div>
          </div>
          <div class="mt-4">
            <div class="mb-2">
              <label v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed') && ((activity.additional_client && getClient.slug != activity.additional_client.slug) || activity.additional_client == null)"
                     class="btn btn-secondary cursor-pointer float-right"
                     qid="create-tp-file-button"
              >
                <input type="file"
                       name="fileupload"
                       value="fileupload"
                       multiple
                       id="file"
                       @change="onFileChange"
                       @click="resetFile"
                >
                <span>
                  <font-awesome-icon icon="cloud-upload-alt" />
                  {{ $t('system.upload') }}
                </span>
              </label>
              <label>
                {{ $t('maintenance.third_parties.create.attachments') }}
                <br>
                <span class="text-muted">({{ $t('attachments.file_size_label') }})</span>
              </label>
            </div>

            <b-alert v-if="error.length"
                     show
                     variant="danger"
                     qid="attachments-tp-error"
            >
              {{ error }}
            </b-alert>

            <div v-for="file in getMedia" v-bind:key="file.id" class="mt-1 section-container">
              <button class="float-right btn btn-link btn-inline-icons"
                      @click="openDeleteAttachmentModal(file)"
                      qid="remove-media-item"
                      v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed') && ((activity.additional_client && getClient.slug != activity.additional_client.slug) || activity.additional_client == null)"
              >
                <font-awesome-icon icon="trash-alt" />
              </button>
              <span class="mr-3 float-right text-muted cursor-pointer"
                    @click="downloadMedia(file)"
                    qid="download-media"
              >
                <font-awesome-icon icon="cloud-download-alt" />
              </span>
              <a @click="downloadMedia(file)" qid="create-tp-media-item">
                {{ file.file_name }}
              </a>
            </div>
            <p v-if="!getMedia.length" class="text-muted section-container">
              {{ $t('system.missing_input') }}
            </p>

            <br>
            </div>
            <div class="mt-4">
              <span class="btn btn-link cursor-pointer float-right"
                    @click="showInput = true"
                    qid="add-another-link"
                    v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed') && ((activity.additional_client && getClient.slug != activity.additional_client.slug) || activity.additional_client == null)"
              >
                <font-awesome-icon icon="plus"/> {{ $t('system.add_link') }}
              </span>
              <label>{{ $t('links.label') }}</label>
              <div v-if="getLinks && getLinks.length">
                <div v-for="link in getLinks" v-bind:key="link.id" class="mt-1 section-container">
                  <button class="float-right btn btn-link btn-inline-icons"
                          @click="openDeleteLinkModal(link)"
                          qid="view-tp-remove-link"
                          v-if="(isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed') && ((activity.additional_client && getClient.slug != activity.additional_client.slug) || activity.additional_client == null)"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </button>
                  <a :href="link.url" target="_blank" qid="create-tp-link-item">
                    {{ link.name ? link.name : link.url }}
                  </a>
                </div>
              </div>
              <p v-if="!getLinks.length" class="text-muted section-container">
                {{ $t('system.missing_input') }}
              </p>
              <div v-if="showInput">
                <label class="mt-2">{{ $t('system.link') }}</label>
                <b-form-input
                  type="text"
                  aria-describedby="inputGroup"
                  v-model="inputLink"
                  :placeholder="$t('maintenance.third_parties.create.links_placeholder')"
                  qid="create-p-links"
                ></b-form-input>
                <label class="mt-2">{{ $t('table.name') }}</label>
                <b-form-input
                  type="text"
                  aria-describedby="inputGroup"
                  v-model="inputLinkName"
                  :placeholder="inputLink ? inputLink : $t('table.name')"
                  qid="create-p-links"
                ></b-form-input>
                <div class="text-right mt-2">
                  <span class="btn btn-success cursor-pointer mr-0"
                        id="inputGroup"
                        @click="handleLink"
                        qid="handle-link-save"
                  >
                    {{ $t('system.save') }}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>

    <ConfirmRemoveAttachment
        :object="selectedFile"
        :show="showDeleteAttachmentModal"
        type="file"
        @closeConfirmModal="closeDeleteAttachmentModal"
    ></ConfirmRemoveAttachment>

    <ConfirmRemoveAttachment
        :object="selectedLink"
        :show="showDeleteLinkModal"
        type="link"
        @closeConfirmModal="closeDeleteLinkModal"
    ></ConfirmRemoveAttachment>


      <b-modal ref="observations" @hide="hideObservations" modal-class="task-modal" size="xl" hide-footer v-if="activity.control_type === 'one time' && (activity.type === 'control_assessment' || activity.type === 'risk_assessment' || activity.type === 'bia_assessment')">
        <template #modal-header="{ close }">
          <div class="" style="width: 100%;">
          <span @click="hideObservations" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
            <h5 class="mb-1 text-center" qid="update-user-title">
              {{ $t('projects.observations.title') }}
            </h5>
          </div>
        </template>
        <ObservationsTable :object="activity" @updated="updateActivity(false)" ></ObservationsTable>
      </b-modal>

  </div>
</template>

<script>
import piincHttp from '@/util/configureAxios.js'
import {config} from '@/config.js'
import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';
import ObservationsTable from '@/views/Projects/ObservationsTable.vue';
import DownloadAllAttachmentsModal from '@/views/Projects/DownloadAllAttachmentsModal.vue';
export default {
  name: 'Reporting',
  data: function() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('dashboard.title'),
          to: '/'
        },
        {
          text: this.$t('projects.title'),
          to: '/projects'
        }
      ],
      activity: {},
      showFullDescription: false,
      descriptionLength: config.descriptionLength,
      edit: this.$route.params.edit,
      selectedSort: '',
      selectedDirection: '',
      lbTableItems: [],
      lbLoading: true,
      showInput: false,

      itemToDelete: {},
      users: [],
      error: '',
      lbForm: {
        name: '',
        article_reason: '',
        links: []
      },
      inputLink: '',
      inputLinkName: '',
      selectedFiles: [],
      selectedFile: {},
      selectedLink: {},
      showDeleteAttachmentModal: false,
      showDeleteLinkModal: false,
      showDescriptionInput: false,
      activityEditSummary: '',

      // Tabs
      showActivityTab: false,
      showProcessTab: false,
      showControlTab: false,
      showSystemTab: false,
      showAssessmentTab: false,
      showThirdPartyTab: false,
      showScheduleTab: false,
      showHistoryTab: false,
      showObservationModal: false,
      buttonDisabled: false,
      tabIndex: 0,
      myGuides: [],
      open_tasks: {},
      closed_tasks: {},
      showStatusForm: false,
      options: [
        { name: this.$t('system.pending'), value: 'pending' },
        { name: this.$t('system.in_progress'), value: 'in progress' },
        { name: this.$t('system.completed'), value: 'completed' },
        { name: this.$t('system.closed'), value: 'closed' }
      ],
      selectedStatus: [],
      selectedTask: [],
      selectedAssignUsers: [],
      itemObject: {},
      reportError: '',
      allUsers: []
    }
  },
  props: {
    object: {
      type: Object
    },
    measureTask: {
      type: Object
    }
  },
  computed: {
    getClient() {
      return this.$store.state.client
    },
    getFiles() {
      return this.selectedFiles
    },
    getUser() {
      return this.$store.state.user
    },
    getMedia() {
      let media = []

      media = _.filter(this.activity.media, function(mediaItem) {
        return mediaItem.collection_name == 'report';
      });

      return media
    },
    getLinks() {
      let links = []

      links = _.filter(this.activity.links, function(link) {
        return link.collection == 'report';
      });

      return links
    }
  },
  watch: {
    // Watch when client changes in store and trigger api reload
    getClient: function (newValue, oldValue) {
      if (newValue && oldValue && newValue.slug && oldValue.slug && newValue.slug !== oldValue.slug) {
        this.goToList()
      } else {
        this.loadContent(true);
      }
    },
    object: function (value) {
      this.activity = value
    }
  },
  components: {
    ConfirmRemoveAttachment,
    ObservationsTable,
    DownloadAllAttachmentsModal
  },
  methods: {
    goToList() {
    },
    showObservations() {
      this.$refs['observations'].show();
    },
    hideObservations() {
      // console.log('aaaaa');
      // this.$refs['observations'].hide();
      // this.$nextTick(() => {
      //   window.scrollTo({
      //     top: 0,
      //     behavior: 'smooth'
      //   });
      // });
    },
    resetFile() {
      let imagefile = document.querySelector('#file');
      imagefile.value = '';
    },
    loadContent(breadcrumbPush) {
      let self = this;
      piincHttp.get('projects/' + this.$route.params.slug, { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.activity = response.data;

        if(self.activity.type === 'control_assessment' || self.activity.type === 'risk_assessment' || self.activity.type === 'bia_assessment'){
          self.breadcrumbItems.push({text: self.$t('projects.compliance_activity_table.assessment_title'), to: '/projects/assessment-projects'});
        }else if(self.activity.type === 'update' || self.activity.type === 'validation'){
          self.breadcrumbItems.push({text: self.$t('projects.compliance_activity_table.asset_title'), to: '/projects/asset-projects'});
        }else if(self.activity.type === 'control' || self.activity.type === 'security_measure'){
          self.breadcrumbItems.push({text: self.$t('projects.compliance_activity_table.control_title'), to: '/projects/control-projects'});
        }

        if (breadcrumbPush) {
          // Push Third party name in breadcrumb array
          self.breadcrumbItems.push({text: self.activity.title, active: true});
        }



      }, function() {});
    },
    showViewTaskModal(object) {
      let self = this;
      this.error = '';

      this.selectedTask = object
      this.$refs['view-task-modal'].show()
    },
    hideViewTaskModal() {
      let self = this;
      this.error = '';

      this.selectedTask = {}
      this.$refs['view-task-modal'].hide()
    },
    updateActivity() {
      this.loadContent();
    },
    historyTabClicked() {
      this.showHistoryTab = true;
      this.showSystemTab = false;
      this.showThirdPartyTab = false;
      this.showProcessTab = false;
      this.showActivityTab = false;
    },
    basicTabClicked() {
      this.showHistoryTab = false;
      this.showSystemTab = false;
      this.showThirdPartyTab = false;
      this.showProcessTab = false;
      this.showActivityTab = false;
    },
    activityTabClicked() {
      this.showActivityTab = true;
      this.showHistoryTab = false;
    },
    systemTabClicked() {
      this.showSystemTab = true;
      this.showHistoryTab = false;
    },
    assessmentTabClicked() {
      this.showAssessmentTab = true;
      this.showHistoryTab = false;
      this.showSystemTab = false;
      this.showThirdPartyTab = false;
      this.showProcessTab = false;
      this.showActivityTab = false;
    },
    thirdPartyTabClicked() {
      this.showThirdPartyTab = true;
      this.showHistoryTab = false;
    },
    processTabClicked() {
      let self = this
      this.showHistoryTab = false;
      this.showProcessTab = true;
    },
    controlTabClicked() {
      this.showControlTab = true;
      this.showHistoryTab = false;
    },
    scheduleTabClicked() {
      this.showScheduleTab = true;
      this.showHistoryTab = false;
      this.showSystemTab = false;
      this.showThirdPartyTab = false;
      this.showProcessTab = false;
      this.showActivityTab = false;
    },
    handleCloseViewPModal () {
      this.loadPolicies();
      this.viewPolicyModal = false;
      this.selectedPolicy = []
    },
    showDescriptionInputFunction() {
      this.showDescriptionInput = true;
      this.activityEditSummary = this.activity.summary;
    },
    formDescriptionSubmit() {
      let self = this;
      piincHttp.put('projects/' + this.$route.params.slug, {
        summary: this.activityEditSummary
      }).then(function(response) {
        self.activity = response.data;
        self.showDescriptionInput = false;
        self.$toastr('success', self.$t('projects.update.success'))
      }, function(error) {
        if (error.status !== 200) {
          self.error = error.data.message;
        }
      })
    },
    generateReport(){
      this.successMessage = ''
      let self = this;
      let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';
      piincHttp.get('projects/' + this.$route.params.slug + '/generate-report?lang='+language
      ).then(function(response) {
        self.disablePage = false;
        self.activity = response.data;
        if (response.status == 200) {
          self.successMessage = self.$t('reports.success_message');
        }
      }, function(error) {
        self.buttonDisabled = false;
        self.disablePage = false;
        if (error.data.message == 'Assessment template is deleted.') {
          self.reportError = self.$t('reports.assessment_error');
        }
      });
    },
    cancelDescriptionForm() {
      this.showDescriptionInput = false;
      this.activityEditSummary = this.activity.summary;
    },
    openViewPolicyModal(object) {
      this.selectedPolicy = object
      this.viewPolicyModal = true
    },
    reloadContent(object) {
      if (this.tabIndex == 1) {
        this.tabIndex = 0
      }
      // If slug is not changed, just reload content
      if (object.slug == this.activity.slug) {
        // Remove last item in breadcrumbs, because it will be added again on load content
        this.breadcrumbItems.pop();
        this.loadContent(true);
      } else {
        // If slug changed, reload state with new param
        this.$router.push({ name: 'projects.view', params: { slug: object.slug }})
      }
    },
    getUserAvatar(user){
      if (user && user.avatar) {
        return user.avatar;
      } else {
        return require('@/assets/avatar.png');
      }
    },
    showConfirmDeleteModal(object) {
      this.itemToDelete = object
      this.$refs['delete-activity'].show()
    },
    hideConfirmDeleteModal() {
      this.itemToDelete = {}
      this.$refs['delete-activity'].hide()
    },
    confirmDelete(object) {
      let self = this;
      piincHttp.delete('projects/' + object.slug).then(function() {
        self.hideConfirmDeleteModal();
        self.goToList();
        // Trigger toastr message after modal close
        self.$toastr('success', self.$t('projects.delete.success'))
      }, function() {});
    },
    handleLink() {
      let self = this;
      let formData = new FormData();

      if (!this.inputLink.startsWith('http')) {
        this.inputLink = 'http://' + this.inputLink
      }

      formData.append("url", this.inputLink);
      formData.append("name", this.inputLinkName);
      formData.append("model_type", this.activity.who_am_i);
      formData.append("model_id", this.activity.id);
      formData.append("collection", "report");

      piincHttp.post('links', formData).then(function() {
        // Remove last item in breadcrumbs, because it will be added again on load content
        self.breadcrumbItems.pop();
        self.$toastr('success', self.$t('maintenance.third_parties.link_add_success'))
        self.loadContent()
        self.inputLink = ''
        self.inputLinkName = ''
        self.showInput = false
      }, function() {})
    },
    downloadMedia(object){
      piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = object.file_name;

        link.click();
        link.remove()
        window.URL.revokeObjectURL(url);
      }, function() {});
    },
    openDeleteAttachmentModal(object) {
      this.selectedFile = object
      this.showDeleteAttachmentModal = true
    },
    closeDeleteAttachmentModal () {
      this.selectedFile = {}
      this.showDeleteAttachmentModal = false;
      // Remove last item in breadcrumbs, because it will be added again on load content
      this.breadcrumbItems.pop();
      this.loadContent();
    },
    openDeleteLinkModal(object) {
      this.selectedLink = object
      this.showDeleteLinkModal = true
    },
    closeDeleteLinkModal () {
      this.selectedLink = {}
      this.showDeleteLinkModal = false;
      // Remove last item in breadcrumbs, because it will be added again on load content
      this.breadcrumbItems.pop();
      this.loadContent();
    },
    onFileChange(file) {
      let files = file.target.files;
      let self = this;
      let formData = new FormData();
      let sizeError = false;

      for (var i = 0; i < files.length; i++) {
        // Equal to 10 MB
        if (files[i].size > 10485760) {
          sizeError = true;
        }
        formData.append('document[]', files[i]);
      }

      formData.append("name", "documents");
      formData.append("model_type", this.activity.who_am_i);
      formData.append("model_id", this.activity.id);
      formData.append("collection", "report");

      if (sizeError) {
        this.error = this.$t('system.file_size')
      } else {
        piincHttp.post('documents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function() {
          // Remove last item in breadcrumbs, because it will be added again on load content
          self.breadcrumbItems.pop();
          self.$toastr('success', self.$t('maintenance.third_parties.media_add_success'))
          self.error = ''
          self.loadContent()
        }, function(error) {
          self.error = error.data.message;
          if (error.data.errors['document.0']) {
            self.error += ' ' + error.data.errors['document.0'][0]
          }
        })
      }
    },
    loadGuides() {
      let self = this;
      let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

      piincHttp.get('guides', { params:
        {
          per_page: 100,
          language: 'en',
          client: this.getClient.slug
        }
      }).then(function(response) {
        if (response.data.items.length) {
          self.myGuides = response.data.items
        }
      }, function() {});
    },
    showFormFunction() {
      let self = this
      this.selectedStatus = []
      if (this.activity.status) {
        this.selectedStatus = _.find(this.options, function(item) {
          return item.value === self.activity.status;
        });
      }
      this.showStatusForm = true
    },
  },
  created () {
    this.activity = this.object;
    if (this.getClient && this.getClient.slug) {
      this.loadContent(true);
    }

    this.loadGuides();

  }
}
</script>
